import React from 'react';
import styled, { css, keyframes } from 'styled-components';

export const fadeInAndOut = keyframes`
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
`;
export const fadeAnimation = css`
  animation: ${fadeInAndOut} 1.2s ease-in-out infinite;
`;

type IProps = {
  width?: string;
  height?: string;
};

const SkeletonFadeInAndOut = ({
  width = '300px',
  height = '200px',
}: IProps) => <StyledContainer width={width} height={height} />;

const StyledContainer = styled.div<{ width?: string; height?: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: rgba(255, 255, 255);
  border-radius: 10px;
  ${fadeAnimation}
`;

export default SkeletonFadeInAndOut;
