import { dynamic } from 'src/helpers/dynamic';
import type { StoreType } from 'src/store/appBanners/types';

export const getStoreTypesByPlatform = (
  isIOS: boolean,
  isAndroid: boolean,
): StoreType[] => {
  if (isIOS) return ['appStore'];
  if (isAndroid) return ['googlePlay', 'ruStore', 'appGallery'];
  return ['googlePlay', 'appStore', 'ruStore', 'appGallery'];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ICONS: Record<StoreType, (props: any) => JSX.Element> = {
  appStore: dynamic(
    /* webpackChunkName: "toAppStore" */ () =>
      import('@lt/components/icons-react/rebrand/Appstore'),
  ),
  googlePlay: dynamic(
    /* webpackChunkName: "toGooglePlay" */ () =>
      import('@lt/components/icons-react/rebrand/Googleplay'),
  ),
  appGallery: dynamic(
    /* webpackChunkName: "toAppGallery" */ () =>
      import('@lt/components/icons-react/rebrand/Huawei'),
  ),
  ruStore: dynamic(
    /* webpackChunkName: "toRuStore" */ () =>
      import('@lt/components/icons-react/rebrand/Rustore'),
  ),
};
