/* This file only for analytics selectors */
import { get, isArray, mapValues, unset } from 'lodash';

import { createSelector } from '@reduxjs/toolkit';
import type { FilterOneData } from 'src/components/Filters/components/FilterOneSlider/types';
import type { FilterSliderData } from 'src/components/Filters/components/FilterSlider/types';
import { getTourOperatorNameById } from '../../CheckoutRoutes/PackageCheckout/store/actualize-alternatives';
import type { FlightsFilter } from '../../CheckoutRoutes/PackageCheckout/store/flights-filters';
import {
  getRawFlightsFilterData,
  getRawFlightsFiltersData,
} from '../../CheckoutRoutes/PackageCheckout/store/flights-filters';

import {
  cheapestBusinessFlightSelector,
  cheapestFlightSelector,
  allFlightsSelector,
} from '../../CheckoutRoutes/PackageCheckout/store/flights/selectors';

import { getSelectedFlightData } from './order';
import { getOperatorId, getTourId } from './package';

export const getOriginDepartureFromFlight = createSelector(
  [getSelectedFlightData, cheapestFlightSelector],
  (selectedFlight, cheapestFlight) => {
    const flight = selectedFlight || cheapestFlight;
    return get(flight, 'to.origin.city.name');
  },
);

export const getArrivalToDateFromFlight = createSelector(
  [
    getSelectedFlightData,
    cheapestFlightSelector,
    cheapestBusinessFlightSelector,
  ],
  (selectedFlight, cheapestFlight, cheapestBusinessFlight) => {
    const flight = selectedFlight || cheapestFlight || cheapestBusinessFlight;
    return get(flight, 'to.arrival');
  },
);

/**
 * Возвращает массив объектов опций фильтров для аналитики
 */
const getOptionObjectForAnalytics = (
  filterOptions: FlightsFilter[] | FilterSliderData | FilterOneData,
) => {
  if (isArray(filterOptions))
    return filterOptions.map((option) => ({
      value: option.id,
      count: option.suitable_flights,
      selected: Boolean(option.selected),
      disabled: option.disabled,
    }));

  // Сделала так, потому что мне не сказали, как это должно нормално быть

  if (
    filterOptions &&
    ('selected_time_from' in filterOptions ||
      'selected_time_to' in filterOptions)
  )
    return {
      value: [filterOptions.selected_time_from, filterOptions.selected_time_to],
      count: filterOptions.suitable_flights,
    };

  if (filterOptions && 'selected_duration' in filterOptions)
    return {
      value: filterOptions.selected_duration,
      count: filterOptions.suitable_flights,
    };

  return {};
};
/**
 * Возвращает информацию  фильтрам в виде JSON-строки для отправки в аналитику
 */
export const filtersForAnalyticsSelector = createSelector(
  [getRawFlightsFiltersData],
  (filtersData) =>
    JSON.stringify(
      mapValues(filtersData, (filter) =>
        getOptionObjectForAnalytics(
          // Для вложенных фильтров (фильтр аэропортов) берем поле airports
          isArray(filter) ? filter : filter.airports,
        ),
      ),
    ),
);

/**
 * Возвращает информацию о отображенных в фильтре ТО операторах
 */
export const shownOperatorsSelector = (state) =>
  getRawFlightsFilterData(state, 'operators').map((opInfo) => opInfo.id);

export const shownOperatorsForAnalyticsSelector = (state) =>
  shownOperatorsSelector(state).join('|');

export const flightsForAnalyticsSelector = createSelector(
  [
    allFlightsSelector,
    getTourId,
    (state) => (tourId: string) => getTourOperatorNameById(state, tourId),
  ],
  (flights, tourId, getTourOperatorName) =>
    flights.map((flight) => {
      unset(flight, ['to', 'airline', 'logo']);

      flight.back.forEach((backFlight) => {
        unset(backFlight, ['airline', 'logo']);
      });

      return {
        ...flight,
        is_alternative: tourId !== flight.back[0].tour_id,
        operator_name: getTourOperatorName(flight.back[0].tour_id),
      };
    }),
);

export const flightsCountForAnalyticsSelector = createSelector(
  [allFlightsSelector, getOperatorId],
  (flights, originalOperatorId) => {
    const operatorFlightsCountObj = {};
    let originalPackageFlightsCount = 0;
    let altPackagesFlightsCount = 0;
    flights.forEach((pair) => {
      pair.back.forEach((flight) => {
        if (operatorFlightsCountObj[flight.operator_id] === undefined) {
          operatorFlightsCountObj[flight.operator_id] = 1;
        } else {
          operatorFlightsCountObj[flight.operator_id] += 1;
        }

        if (flight.operator_id === originalOperatorId) {
          originalPackageFlightsCount += 1;
        } else {
          altPackagesFlightsCount += 1;
        }
      });
    });

    return {
      flightsByOperators: {
        keys: Object.keys(operatorFlightsCountObj).join('|'),
        values: Object.values(operatorFlightsCountObj).join('|'),
      },
      originalPackageFlightsCount,
      altPackagesFlightsCount,
    };
  },
);

export const origPacakgeMinFuelChargeSelector = createSelector(
  [allFlightsSelector, getTourId],
  (flights, origPackageTourId) =>
    flights.length &&
    Math.min(
      ...flights
        .map((flightPair) => flightPair.back)
        .flat()
        .filter(({ tour_id }) => tour_id === origPackageTourId)
        .map(({ fuel_charge }) => fuel_charge),
    ),
);
