/* eslint-disable no-param-reassign */
import type { PaymentMethod } from '@lt/api/dist/methods/orders/types';
import type { Bank, PaylateSuccess } from '@lt/api/dist/methods/payment/types';
import type { PaymentType } from '@lt/api/dist/types/payment';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { PaymentStateType, ThreeDSData } from './types';

const initialState: PaymentStateType = {
  paymentMethodList: [],
  selectedMethod: null,
  paymentAmount: null,
  orderId: null,
  orderPin: null,
  paymentId: null,
  isPaid: false,
  uniqueInfo: {
    tariffs: [],
    redirectMobileURL: null,
    paylateData: null,
    isCardValid: false,
    cardInfo: {
      number: '',
      expMonth: '',
      expYear: '',
      cvv: '',
    },
    banks: [],
  },
  ofertaText: null,
};
export const name = 'payment';

const payment = createSlice({
  name,
  initialState,
  reducers: {
    setPaymentAmount(state, action: PayloadAction<number | null>) {
      state.paymentAmount = action.payload;
    },

    setPaymentMethod(state, action: PayloadAction<PaymentType>) {
      state.selectedMethod = action.payload;
    },
    setPaymentData(
      state,
      action: PayloadAction<{ methods: PaymentMethod[]; ofertaText: string }>,
    ) {
      const { methods, ofertaText } = action.payload;
      state.paymentMethodList = methods;
      state.ofertaText = ofertaText;

      methods.find((method) => {
        if (method.type === 't_bank_credit') {
          state.uniqueInfo.tariffs = method.additionalData;
          state.uniqueInfo.selectedTariffID ??= method.additionalData[0].id;
          return true;
        }

        return false;
      });
    },

    setOrderInfo(
      state,
      action: PayloadAction<{
        orderId: number | null;
        pin: string | null;
        paymentId?: number;
      }>,
    ) {
      const {
        payload: { orderId, pin, paymentId },
      } = action;
      state.orderId = orderId;
      state.orderPin = pin;
      if (paymentId) state.paymentId = paymentId;
    },

    setCardDataAction(
      state,
      action: PayloadAction<{
        number: string;
        expMonth: string;
        expYear: string;
        cvv: string;
      }>,
    ) {
      state.uniqueInfo.cardInfo = action.payload;
    },
    setCardValidityAction(state, action: PayloadAction<boolean>) {
      state.uniqueInfo.isCardValid = action.payload;
    },

    set3dsDataV1(state, action: PayloadAction<ThreeDSData<'v1'>>) {
      state.uniqueInfo.data3ds = action.payload;
    },
    set3dsDataV2(state, action: PayloadAction<ThreeDSData<'v2'>>) {
      state.uniqueInfo.data3ds = action.payload;
    },

    openPay2meFrame(state, action: PayloadAction<string>) {
      state.uniqueInfo.pay2meURL = action.payload;
    },

    setPaylateData(state, action: PayloadAction<PaylateSuccess>) {
      state.uniqueInfo.paylateData = action.payload;
    },

    payCardSuccessed(state, action: PayloadAction<number>) {
      state.paymentId = action.payload;
    },

    finishPayment(state) {
      state.isPaid = true;
    },

    /**
     * Оплата ТБанк рассрочкой
     */
    setCurrentTariff(state, action: PayloadAction<string>) {
      state.uniqueInfo.selectedTariffID = action.payload;
    },

    /**
     * Оплата СБП
     */
    setExpiresIn: (
      state,
      action: PayloadAction<PaymentStateType['uniqueInfo']['expiresIn']>,
    ) => {
      state.uniqueInfo.expiresIn = action.payload;
    },
    setQRCodeURL: (state, action: PayloadAction<string>) => {
      state.uniqueInfo.qrURL = action.payload;
    },
    setRedirectMobileURL: (state, action: PayloadAction<string>) => {
      state.uniqueInfo.redirectMobileURL = action.payload;
    },
    setBanks: (state, action: PayloadAction<Bank[]>) => {
      state.uniqueInfo.banks = action.payload;
    },
  },
});

export const {
  setPaymentAmount,
  setPaymentMethod,
  setPaymentData,
  setOrderInfo,
  setCardDataAction,
  setCardValidityAction,
  set3dsDataV1,
  set3dsDataV2,
  payCardSuccessed,
  setPaylateData,
  finishPayment,
  openPay2meFrame,
  setCurrentTariff,
  setBanks,
  setQRCodeURL,
  setRedirectMobileURL,
  setExpiresIn,
} = payment.actions;

export default payment.reducer;
