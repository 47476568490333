import React, { lazy, Suspense, useState } from 'react';
import type { FC } from 'react';
import cn from 'classnames';

import HeaderMenu, {
  HeaderMenuCategory,
} from '@lt/components/components/HeaderMenu';

import { isSSR } from 'src/helpers/ssr';
import HeaderFeedback from '../../../HeaderFeedback';
import HeaderWishlist from '../../../HeaderWishlist';

import type { IHeaderMenu } from '../../types';
import styles from './desktopMenu.module.css';
import {
  HEADER_CONTACT_US_TEST_ID,
  HEADER_SERVICES_TEST_ID,
  HEADER_MY_LIST_TEST_ID,
  HEADER_HELP_TEST_ID,
  HEADER_LOGIN_TEST_ID,
  HEADER_CASHBACK_TEST_ID,
} from '../../constants';
import HeaderCashback from '../../../HeaderCashback';

const HeaderAuth = lazy(
  () =>
    import(
      /* webpackChunkName: "HeaderAuth" */ 'src/components/Header/components/HeaderAuth'
    ),
);

const DesktopMenu: FC<IHeaderMenu> = ({
  services,
  help,
  className,
  isCollapsed,
  showAuthorizationBlock = true,
  showWishlist = true,
  showFeedback,
  isHomepage,
  isWhiteLabel,
  isLoggedIn,
}) => {
  /*
  Стейт showAuthFromWishlist нужен, чтобы при вызове авторизации из избранного  
  отображалась только одна форма авторизации
*/
  const [showAuthFromWishlist, setShowAuthFromWishlist] =
    useState<boolean>(false);
  return (
    <HeaderMenu
      className={className}
      isCompact={isCollapsed}
      isHomepage={isHomepage}
    >
      {/* https://leveltravel.atlassian.net/browse/LT-31772 */}
      {showFeedback && (
        <li
          className={styles.categoryWrapper}
          data-testid={HEADER_CONTACT_US_TEST_ID}
        >
          <HeaderFeedback
            isCompact={isCollapsed}
            isHomepage={isHomepage}
            className={cn(styles.category, {
              [styles.wlCategory]: isWhiteLabel && !isHomepage,
            })}
          />
        </li>
      )}
      {services && services.items && services.items.length !== 0 && (
        <HeaderMenuCategory
          className={cn(styles.category, {
            [styles.fullServices]: !isCollapsed,
            [styles.wlCategory]: isWhiteLabel && !isHomepage,
          })}
          isCompact={isCollapsed}
          isHomepage={isHomepage}
          data-testid={HEADER_SERVICES_TEST_ID}
          {...services}
        />
      )}
      {showWishlist && (
        <li
          className={styles.categoryWrapper}
          data-testid={HEADER_MY_LIST_TEST_ID}
        >
          <HeaderWishlist
            className={cn(styles.category, {
              [styles.wlCategory]: isWhiteLabel && !isHomepage,
            })}
            isCompact={isCollapsed}
            setShowAuthFromWishlist={setShowAuthFromWishlist}
          />
        </li>
      )}
      {help && help.items && help.items.length !== 0 && (
        <HeaderMenuCategory
          className={cn(styles.category, {
            [styles.wlCategory]: isWhiteLabel && !isHomepage,
          })}
          isCompact={isCollapsed}
          isHomepage={isHomepage}
          data-testid={HEADER_HELP_TEST_ID}
          {...help}
        />
      )}
      {isLoggedIn && !isWhiteLabel && !isCollapsed && (
        <li data-testId={HEADER_CASHBACK_TEST_ID}>
          <HeaderCashback />
        </li>
      )}
      {showAuthorizationBlock && !isSSR && (
        <li
          className={styles.categoryWrapper}
          data-testid={HEADER_LOGIN_TEST_ID}
        >
          <Suspense fallback={<div />}>
            <HeaderAuth
              className={cn(styles.category, {
                [styles.wlCategory]: isWhiteLabel && !isHomepage,
              })}
              isCompact={isCollapsed}
              showAuthFromWishlist={showAuthFromWishlist}
              data-testid={HEADER_LOGIN_TEST_ID}
            />
          </Suspense>
        </li>
      )}
    </HeaderMenu>
  );
};
export default DesktopMenu;
