/**
 * При помощи данной переменной мы понимаем находимся ли мы в telegram браузере или нет
 */
export const isTelegramWebApp = (() => {
  try {
    // @ts-ignore
    return typeof TelegramWebviewProxy !== 'undefined';
  } catch (e) {
    return true;
  }
})();
