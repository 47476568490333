/* eslint-disable react/no-array-index-key */
// Массив navigationBlocks статический, поэтому использовать индекс для key безопасно
import React from 'react';
import cn from 'classnames';

import { Link } from '@lt/components';

import { navigationBlocks } from './data';
import type { PropsFromRedux as Props } from '.';
import styles from './navigationLinks.module.css';

const NavigationLinks = ({ isMobile }: Props) => (
  <div
    className={cn(styles.container, {
      [styles.desktopContainer]: !isMobile,
      [styles.mobileContainer]: isMobile,
    })}
  >
    {navigationBlocks.map(({ title, items }) => (
      <section
        className={cn(styles.block, { [styles.mobileBlock]: isMobile })}
        key={title}
      >
        <h3
          className={cn(styles.text, styles.title, {
            [styles.mobileTitle]: isMobile,
          })}
        >
          {title}
        </h3>
        <ul
          className={cn(styles.list, {
            [styles.desktopList]: !isMobile,
            [styles.mobileList]: isMobile,
          })}
        >
          {items.map(({ links }, idx) => (
            <li className={styles.item} key={idx}>
              {links.map(({ href, text }) => (
                <Link
                  className={cn(styles.text, { [styles.mobileText]: isMobile })}
                  key={text}
                  href={href}
                >
                  {text}
                </Link>
              ))}
            </li>
          ))}
        </ul>
      </section>
    ))}
  </div>
);

export default NavigationLinks;
