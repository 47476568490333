import React from 'react';
import styled, { css } from 'styled-components';

import type { ICigaretteSkeleton } from './types';
import { CIGARETTE_SKELETON_TEST_ID } from '../Cigarette/components/LazyCigarette/constants';

export const CigaretteSkeleton = (props: ICigaretteSkeleton) => {
  const { hasButton = true, hasMaxWidth = true } = props;

  return (
    <StyledContainer
      $hasMaxWidth={hasMaxWidth}
      data-testid={CIGARETTE_SKELETON_TEST_ID}
    >
      <StyledSkeleton $hasButton={hasButton}>
        <StyledLoadingDots />
      </StyledSkeleton>
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ $hasMaxWidth: boolean }>`
  z-index: 1;
  position: relative;
  width: 100%;
  min-height: 56px;

  ${({ $hasMaxWidth }) =>
    $hasMaxWidth &&
    `
      max-width: 1200px;

  `}
`;

const StyledSkeleton = styled.div<{ $hasButton: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--white);
  z-index: -1;
  border-radius: var(--border-radius);

  ${({ $hasButton }) => $hasButton && buttonSkeletonStyles}

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const buttonSkeletonStyles = css`
  &:after {
    width: 20px;
    height: 20px;
    content: '';
    position: absolute;
    border-radius: 100%;
    box-sizing: border-box;
    right: 60px;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 2.5px solid var(--white);
    border-left-color: rgba(255, 255, 255, 0.4);
    border-bottom-color: rgba(255, 255, 255, 0.4);
    animation: lt-form-spinner 1s linear infinite;
  }

  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 150px;
    background-color: var(--orange);
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
    margin: auto;
  }
`;

const StyledLoadingDots = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  margin-left: 40px;
  border-radius: 5px;
  background-color: rgb(145, 148, 153);
  color: rgb(145, 148, 153);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: rgb(145, 148, 153);
    color: rgb(145, 148, 153);
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: rgb(145, 148, 153);
    color: rgb(145, 148, 153);
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: rgb(145, 148, 153);
    }
    50%,
    100% {
      background-color: rgba(145, 148, 153, 0.2);
    }
  }
`;
