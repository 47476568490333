import React, { Suspense, lazy } from 'react';

import { isSSR } from 'src/helpers/ssr';
import { useForceRender } from 'src/hooks/useForceRender';

import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import FooterAppBanners from 'src/components/Layout/components/FooterAppBanners';
// import AdfoxGalleryBanner from '../AdfoxGalleryBanner';
import BannerController from 'src/components/Banners';
import CountriesList from '../CountriesList';
import DynamicDataWrapper from '../MobileSearchBlock/components/DynamicDataWrapper';
import HomeLtBottomPromo from '../MobileSearchBlock/components/HomeLtBottomPromo';
import Relinking from '../Relinking';
import SearchForm from '../SearchForm';
import StatisticsWidget from '../StatisticsWidget';

import type { PropsFromRedux as IDesktop } from '.';
import WLHeaderDescription from '../Header/WL/WLHeaderDescription';
import styles from './desktop.module.css';
import { NpsState } from '../NpsForm/NpsForm';
import BackgroundImage from './components/BackgroundImage';

const NpsForm = lazy(
  () => import(/* webpackChunkName: "NpsForm" */ '../NpsForm'),
);

const Desktop = ({
  isWhitelabel,
  isStatisticWidgetDisabled,
  npsState,
}: IDesktop): JSX.Element => {
  const { countForceRender } = useForceRender();

  return (
    <>
      <div className={styles.backgroundHeadContainer}>
        {!isSSR && (
          <>
            <BackgroundImage />
            {npsState !== NpsState.Hide && (
              <Suspense fallback={<div />}>
                <NpsForm npsState={npsState} />
              </Suspense>
            )}
            <div className={styles.headContentContainer}>
              <BannerController />
              <div className={styles.headerShadow}>
                <div className={styles.container}>
                  <Header />
                </div>
              </div>
              <div className={styles.backgroundContainer}>
                {isWhitelabel ? <WLHeaderDescription /> : null}
                <DynamicDataWrapper>
                  <SearchForm />
                </DynamicDataWrapper>
              </div>
            </div>
          </>
        )}
      </div>
      {/* {!isWhitelabel && <AdfoxGalleryBanner />} */}
      <div className={styles.contentContainer}>
        {!isStatisticWidgetDisabled && <StatisticsWidget />}
        <Relinking isMobile={false} />
        {!isWhitelabel && (
          <>
            <HomeLtBottomPromo />
            <CountriesList />
          </>
        )}
      </div>
      <FooterAppBanners />
      {!isWhitelabel && <Footer key={countForceRender} />}
    </>
  );
};

export default Desktop;
