import React, { useEffect } from 'react';
import cn from 'classnames';

import { Link } from '@lt/components';
import { i18n } from '@lt/localization';
import { useSelector } from 'react-redux';
import { getMobilePlatform } from 'src/store/view/selectors';
import { APP_BANNER_TYPES } from 'src/store/appBanners/constants';
import QRCodeCanvas from 'src/components/QRCodeCanvas';

import type { PropsFromRedux as Props } from '.';
import { getStoreTypesByPlatform, ICONS } from './utils';
import styles from './downloadApp.module.css';

const DownloadApp = ({ appLink, isMobile, getLink }: Props) => {
  const platform = useSelector(getMobilePlatform);

  useEffect(() => {
    if (!appLink)
      getLink(
        isMobile
          ? APP_BANNER_TYPES.BANNER_MOBWEB
          : APP_BANNER_TYPES.BANNER_DESKTOP,
      );
  }, [appLink, getLink, isMobile]);

  return (
    <div
      className={cn(styles.container, { [styles.mobileContainer]: isMobile })}
    >
      <p className={cn(styles.title, { [styles.mobileTitle]: isMobile })}>
        {i18n.t('footer.downloadOurApp')}
      </p>
      {!isMobile && appLink && (
        <QRCodeCanvas text={appLink} options={{ width: 260, version: 6 }} />
      )}
      <div
        className={cn(styles.stores, {
          [styles.mobileStores]: isMobile,
          [styles.desktopStores]: !isMobile,
        })}
      >
        {getStoreTypesByPlatform(
          platform === 'ios',
          platform === 'android',
        ).map((type) => {
          const Icon = ICONS[type];
          return (
            <Link href={appLink} key={type}>
              <Icon height={isMobile ? 44 : 56} />
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default DownloadApp;
