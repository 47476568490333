import type { PayloadAction } from '@reduxjs/toolkit';
import { createAction, createSlice } from '@reduxjs/toolkit';
import type { MobileAppDataProps } from 'src/components/Banners/MobileAppPopup/types';

import {
  GET_DEEPLINK,
  MOVE_TO_APP_CONTINUED_WEB,
  MOVE_TO_APP_OPENED_APP,
  MOVE_TO_APP_SHOWN,
} from './constants';
import type { BannerType } from './types';

const initialState: MobileAppDataProps = {
  shortLink: '',
};

const appBannerSlice = createSlice({
  name: 'bannerAppData',
  initialState,
  reducers: {
    setShortLink: (
      state,
      action: PayloadAction<MobileAppDataProps['shortLink']>,
    ) => {
      state.shortLink = action.payload;
    },
  },
});

export const { setShortLink } = appBannerSlice.actions;

export const getDeeplinkAction = createAction<string>(GET_DEEPLINK);
export const moveToAppShown =
  createAction<BannerType[keyof BannerType]>(MOVE_TO_APP_SHOWN);
export const moveToAppOpenedApp = createAction<BannerType[keyof BannerType]>(
  MOVE_TO_APP_OPENED_APP,
);
export const moveToAppContinuedWeb = createAction<BannerType[keyof BannerType]>(
  MOVE_TO_APP_CONTINUED_WEB,
);

const { name, reducer } = appBannerSlice;
export { reducer as default, name };
