import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import type { PaymentType } from '@lt/api/dist/types/payment';
import { getImmutableCompatible } from '../../utils/immutableUtils';
import { PAYMENT_METHOD_CARD } from './constants';
import type { PaymentStateType } from './types';
import { isPaylate } from './utils';

// selectors
export const selectPayment = (state): PaymentStateType =>
  getImmutableCompatible(state, 'payment');

/**
 * If payment succeeded;
 */
export const selectIsPaid = createSelector(
  selectPayment,
  (payment) => payment.isPaid,
);

/**
 * Payment amount
 */
export const getPaymentAmount = createSelector(
  selectPayment,
  (payment) => payment.paymentAmount,
);

export const select3dsData = (state) => selectPayment(state).uniqueInfo.data3ds;

/**
 * Order info
 */
export const selectOrderID = createSelector(
  selectPayment,
  (payment) => payment.orderId,
);
export const selectOrderPin = createSelector(
  selectPayment,
  (payment) => payment.orderPin,
);

/**
 * Uniteller payment id
 */
export const selectPaymentId = createSelector(
  selectPayment,
  (payment) => payment.paymentId,
);

/**
 * Order is paid
 */
export const getOrderIsPaid = createSelector(
  selectPayment,
  (payment) => payment.isPaid,
);

/**
 * Card data
 */
export const getCardData = createSelector(
  selectPayment,
  (payment) => payment.uniqueInfo.cardInfo,
);
export const getCardIsValid = createSelector(
  selectPayment,
  (payment) => payment.uniqueInfo.isCardValid,
);

/**
 * Payment method:
 */
export const getPaymentMethod = createSelector(
  selectPayment,
  (payment) => payment.selectedMethod,
);
export const getPaymentMethodList = createSelector(selectPayment, (payment) =>
  payment.paymentMethodList?.filter((method) => method.enable),
);

export const selectIsSbpIncluded = createSelector(
  getPaymentMethodList,
  (methods) => Boolean(methods.find((method) => method.type === 'sbp')),
);

export const getDefaultPaymentMethod = createSelector(
  getPaymentMethodList,
  (paymentMethods: unknown[]) => {
    if (
      !(
        paymentMethods &&
        Array.isArray(paymentMethods) &&
        paymentMethods.length > 0
      )
    ) {
      return PAYMENT_METHOD_CARD;
    }
    const paymentMethod = paymentMethods[0];
    if (
      !(
        paymentMethod &&
        typeof paymentMethod === 'object' &&
        'type' in paymentMethod &&
        paymentMethod.type === 'string'
      )
    ) {
      return PAYMENT_METHOD_CARD;
    }
    return paymentMethod.type;
  },
);
export const hasPaymentMethod = createSelector(
  selectPayment,
  (paymentMethods) => !isEmpty(paymentMethods.paymentMethodList),
);
export const getPaymentData = createSelector(
  [getPaymentMethodList, getPaymentMethod],
  (paymentMethods = [], orderPaymentMethod) =>
    paymentMethods.find((item) => item.type === orderPaymentMethod),
);

export const selectPaylateData = createSelector(
  selectPayment,
  (payment) => payment.uniqueInfo.paylateData,
);

/**
 * Возвращает `true` если метод оплаты Paylate
 */
export const getIsPaylate = createSelector(getPaymentMethod, isPaylate);

export const selectPay2meURL = (state) =>
  selectPayment(state).uniqueInfo.pay2meURL;

export const selectTariffs = (state) => selectPayment(state).uniqueInfo.tariffs;
export const selectCurrentTariffID = (state) =>
  selectPayment(state).uniqueInfo.selectedTariffID;

/**
 * Селекторы для СБП
 */
export const selectExpiresIn = (state) =>
  selectPayment(state).uniqueInfo.expiresIn;
export const selectBanks = (state) => selectPayment(state).uniqueInfo.banks;
export const selectQRCodeURL = (state) => selectPayment(state).uniqueInfo.qrURL;
export const selectRedirectMobileURL = (state) =>
  selectPayment(state).uniqueInfo.redirectMobileURL;

export const selectCertainPaymentMethod = (state, type: PaymentType) =>
  getPaymentMethodList(state).find((method) => method.type === type);

export const selectOfertaText = (state) => selectPayment(state).ofertaText;
