import React from 'react';
import { getIsCookieHasAnOption } from 'src/utils/cookiesController';
import CookiesBanner from './CookiesBanner';
import { KEY_COOKIE as mobileBannerCookie } from './MobileAppPopup/constants';
import MobileAppPopupContainer from './MobileAppPopup';
import TopMobileAppBannerContainer from '../TopMobileAppBanner';

interface BannerControllerProps {
  isMobile: boolean;
  isWhiteLabel: boolean;
  isHomepage: boolean;
  isAppBannerEnabled: boolean;
  isCookieBannerDisabled?: boolean;
}

// показыаем куки баннер после баннеров в прил-ки, если они есть
export const BannerController = ({
  isMobile,
  isWhiteLabel,
  isHomepage,
  isAppBannerEnabled,
  isCookieBannerDisabled,
}: BannerControllerProps) => {
  const mobileBannerHiding = getIsCookieHasAnOption(
    mobileBannerCookie,
    'hiding',
  );

  const showCookiesBanner = (mobileBannerHiding && isMobile) || !isMobile;

  const shouldShowCookiesBanner =
    showCookiesBanner && !isCookieBannerDisabled && !isWhiteLabel;

  return (
    <>
      {shouldShowCookiesBanner && <CookiesBanner />}
      {isAppBannerEnabled && isMobile && isHomepage ? (
        <TopMobileAppBannerContainer />
      ) : null}
      {isAppBannerEnabled && isMobile ? <MobileAppPopupContainer /> : null}
    </>
  );
};
