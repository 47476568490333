import React from 'react';

import { CurrencyFormat } from '@lt/components';
import { useTranslation } from '@lt/localization';

import type { IPopularDestinationsOfferRegionWithPrice } from 'src/routes/Home/store/popularDestinationsOffers/types';
import styles from './styles/regionLabels.module.css';

export const RegionLabel = (
  props: IPopularDestinationsOfferRegionWithPrice,
) => {
  const { place, minPrice, link } = props;

  const { t } = useTranslation();

  if (!minPrice) return null;

  return (
    <a className={styles.regionLabelContainer} href={link}>
      <div className={styles.regionPlaceName}>{place}</div>
      <CurrencyFormat
        className={styles.regionPrice}
        value={minPrice}
        prefix={`${t('filters.prices.from')} `}
      />
    </a>
  );
};
