import { createSelector } from '@reduxjs/toolkit';
import { isEmpty, every, pickBy } from 'lodash';
import { getIsHotelOnly } from 'src/store/view/selectors';
import { addDays } from 'date-fns';
import { getFromImmutableState } from '../../../../utils/immutableUtils';
import { MEALS_TYPES_SYMBOLS, SEARCHER_STATE } from '../constants/searcher';
import { getRooms } from './rooms';
import {
  convertClientDateToServerDate,
  convertServerDateToClientDate,
} from '../../../../utils/dateUtils';
import { getNightsRange } from '../../../../utils/searchParamsUtils';
import {
  searchParamsPrimaryStartDateSelector,
  getSearchParamsStartDate,
} from '../../../../store/enqueue/selectors';
import {
  buildFilters,
  getOffersForDate,
  isDateBetweenRange,
  passFilterData,
} from '../../utils/offersUtils';

const getSearcher = (state) => state.get('searcher');

export const getSearcherData = getFromImmutableState(getSearcher);
export const getSearcherState = (state) => getSearcher(state).get('state');
export const getSearcherFetching = createSelector(
  [getSearcherState, getRooms],
  (state, rooms) =>
    (state === SEARCHER_STATE.FETCHING || state === SEARCHER_STATE.INITIAL) &&
    !rooms.length,
);
/** Данный selector отдает дату в очень неудобном формате для использования в коде
 * @example 12.02.2000
 * для использования в коде используйте для преобразования функцию
 * @see convertClientDateToServerDate
 */
export const getSearcherStartDate = (state) =>
  getSearcher(state).get('startDate');
export const getSearcherNights = (state) => getSearcher(state).get('nights');
/** Данный selector отдает дату в очень неудобном формате для использования в коде
 * @example 12.02.2000
 * Этот selector сделал таким же как и getSearcherStartDate ради консистентности
 * для использования в коде используйте для преобразования функцию
 * @see convertClientDateToServerDate
 */
export const getSearcherEndDate = (state) => {
  const startDate = convertClientDateToServerDate(getSearcherStartDate(state));
  const nights = getSearcherNights(state);
  const endDate = addDays(new Date(startDate), nights);
  return convertServerDateToClientDate(endDate);
};

export const getSearcherCarouselDate = (state) =>
  getSearcher(state).get('carouselDate');
export const getSearcherRequestId = (state) =>
  getSearcher(state).get('requestId');

const _getSearcherKidsAges = (state) => getSearcher(state).get('kidsAges');
export const getSearcherKidsAges = getFromImmutableState(_getSearcherKidsAges);
export const getSearcherKidsCount = createSelector(
  getSearcherKidsAges,
  (kidsAges) => (kidsAges ? kidsAges.length : 0),
);
export const getSearcherKidsAgesText = createSelector(
  getSearcherKidsAges,
  (kidsAges) => (kidsAges ? kidsAges.join(',') : ''),
);
export const getSearcherAdults = (state) => getSearcher(state).get('adults');
export const getSearcherTouristsCount = createSelector(
  [getSearcherAdults, getSearcherKidsCount],
  (adults, kids) => adults + kids,
);
export const _getSearcherDeparture = (state) =>
  getSearcher(state).get('departure');
export const getSearcherDeparture = getFromImmutableState(
  _getSearcherDeparture,
);
export const getSearcherFromCity = (state) =>
  getSearcher(state).getIn(['from', 'city', 'name_en']);
export const getSearcherFromCityId = (state) =>
  String(getSearcher(state).getIn(['from', 'city', 'id']));
export const getSearcherFromCountry = (state) =>
  getSearcher(state).getIn(['from', 'country', 'iso2']);
export const getSearcherToCity = (state) =>
  getSearcher(state).getIn(['to', 'city', 'name_en']);
export const getSearcherToCountry = (state) =>
  getSearcher(state).getIn(['to', 'country', 'iso2']);

export const getSearcherInstantConfirm = (state) =>
  getSearcher(state).get('instantConfirm');

export const getSearcherFreeCancel = (state) =>
  getSearcher(state).get('freeCancel');

// Search results offers nights, eg: [6,7,8], [5,6,7,8,9]
export const getSearchResultsNights = createSelector(
  getSearcherNights,
  (searcherNights) => {
    const [nightsFrom, nightsTo] = getNightsRange(searcherNights);
    const nights = Array.from({ length: 1 + (nightsTo - nightsFrom) }).map(
      (_, idx) => nightsFrom + idx,
    );
    return nights;
  },
);

const _getSearcherFilters = (state) => getSearcher(state).get('filters');
export const getSearcherFilters = getFromImmutableState(_getSearcherFilters);

export const getSearcherOperatorsFilter = createSelector(
  [getSearcherFilters],
  (filters) => {
    const filterOperators = Object.entries(filters);
    return Object.fromEntries(filterOperators.filter((e) => Number(e[0])));
  },
);

export const selectedOperatorsArraySelector = createSelector(
  [getSearcherOperatorsFilter],
  (opreratorsFilter) =>
    Object.keys(opreratorsFilter).filter((key) => opreratorsFilter[key]),
);

export const getActiveFilters = createSelector(
  [getSearcherInstantConfirm, getSearcherFreeCancel, getSearcherFilters],
  (instantConfirm, freeCancel, filters) => {
    const combinedFilter = pickBy(
      {
        instantConfirm,
        freeCancel,
        ...filters,
      },
      (item) => item,
    );

    return combinedFilter;
  },
);

/**
 * Все предложения на выбранную в карусели дату с учетом фильтров
 */
export const getOffersForCurrentDate = createSelector(
  [
    getRooms,
    getSearcherCarouselDate,
    getSearcherOperatorsFilter,
    getSearcherInstantConfirm,
    getSearcherFilters,
    getIsHotelOnly,
  ],
  (roomOffers, date, operators, instantConfirm, filters, isHotelOnly) => {
    const startDate = convertClientDateToServerDate(date);
    const options = {
      instantConfirm,
      operators: isHotelOnly ? undefined : passFilterData(operators),
      ltExtras: isHotelOnly ? undefined : passFilterData(filters),
    };

    return getOffersForDate(roomOffers, startDate, isHotelOnly, options);
  },
);

/**
 * Все предложения на выбранную в карусели дату с учетом фильтров и кешбэка
 */
export const getOffersForCurrentDateWithCashbackReducedPrices = createSelector(
  [
    getRooms,
    getSearcherCarouselDate,
    getSearcherInstantConfirm,
    getSearcherFilters,
    getIsHotelOnly,
    getSearcherOperatorsFilter,
  ],
  (roomOffers, date, instantConfirm, filters, isHotelOnly, operators) => {
    const startDate = convertClientDateToServerDate(date);

    const options = {
      instantConfirm,
      operators: isHotelOnly ? undefined : passFilterData(operators),
      ltExtras: passFilterData(filters),
    };

    return getOffersForDate(roomOffers, startDate, isHotelOnly, options);
  },
);

/**
 * Все предложения на выбранную в карусели дату с учетом фильтров питания и кешбэка и питания
 */
export const getOffersForCurrentDateWithMealsFilters = createSelector(
  [getOffersForCurrentDateWithCashbackReducedPrices, getSearcherFilters],
  (roomOffers, mealsFilter) => {
    const mealsBuildFilters = buildFilters(mealsFilter, 'lt_meals');
    const activeMealsBuildFiltersKeys = Object.keys(mealsBuildFilters).filter(
      (key) => mealsBuildFilters[key] === true,
    );
    const mealType = activeMealsBuildFiltersKeys.map((meal) =>
      meal.slice(MEALS_TYPES_SYMBOLS),
    );

    if (mealType.length === 0) return roomOffers;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const roomOfferFiters: any[] = [];
    roomOffers.forEach((roomOffer) => {
      let offers = {};
      mealType.forEach((meal) => {
        const mealFilter = roomOffer.offers[meal];
        if (mealFilter && mealFilter.length !== 0) {
          offers = { ...offers, [meal]: mealFilter };
        }
      });
      if (Object.keys(offers).length > 0) {
        const offersFilter = {
          ...roomOffer,
          offers,
          meal_types: roomOffer?.meal_types.filter((activeMeal) =>
            mealType.includes(activeMeal.id),
          ),
        };
        roomOfferFiters.push(offersFilter);
      }
    });
    return roomOfferFiters;
  },
);

export const getOffersForCurrentDateWithAllHotelFilters = createSelector(
  [getOffersForCurrentDateWithMealsFilters, getSearcherFreeCancel],
  (roomOffers, freeCancel) => {
    if (!freeCancel) {
      return roomOffers;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const roomOffersWithAllHotelFilters: any[] = [];
    roomOffers.forEach((roomOffer) => {
      let offersWithFreeCancel = {};
      roomOffer.meal_types?.forEach(({ id }) => {
        const offersTariff = roomOffer.offers[id];
        let offersWithFilter = {};
        if (offersTariff) {
          offersWithFilter = offersTariff.filter(
            (offer) => offer.rates.cancellation_policy.kind === 'flexible',
          );
        }
        if (Object.keys(offersWithFilter).length > 0) {
          offersWithFreeCancel = {
            ...offersWithFreeCancel,
            [id]: offersWithFilter,
          };
        }
      });
      const offers = offersWithFreeCancel;
      if (Object.keys(offersWithFreeCancel).length > 0) {
        const roomOfferWithAllHotelFilters = { ...roomOffer, offers };
        roomOffersWithAllHotelFilters.push(roomOfferWithAllHotelFilters);
      }
    });
    return roomOffersWithAllHotelFilters;
  },
);

export const getSetDefaultFilters = (state) =>
  getSearcher(state).get('setDefaultFilters');

export const getIsSetDefaultFilters = createSelector(
  [getSetDefaultFilters],
  (defaultFilters) => defaultFilters,
);
/**
 * Все предложения на выбранную в карусели дату
 */
export const getAllOffersForCurrentDate = createSelector(
  [getRooms, getSearcherCarouselDate, getIsHotelOnly],
  (roomOffers, date, isHotelOnly) =>
    getOffersForDate(
      roomOffers,
      convertClientDateToServerDate(date),
      isHotelOnly,
    ),
);

/**
 * Есть ли предложения на выбранную в карусели дату
 */
export const getHasOffersForCurrentDate = createSelector(
  getAllOffersForCurrentDate,
  (offers) => !isEmpty(offers),
);

export const getHasOffersInstantConfirm = createSelector(
  [
    getRooms,
    getSearcherCarouselDate,
    getSearcherOperatorsFilter,
    getIsHotelOnly,
  ],
  (roomOffers, date, operators, isHotelOnly) => {
    const showAll = isEmpty(operators) || every(operators, (v) => v === false);
    const offers = getOffersForDate(
      roomOffers,
      convertClientDateToServerDate(date),
      isHotelOnly,
      {
        operators: showAll ? undefined : operators,
        instantConfirm: true,
      },
    );
    return !offers.length;
  },
);

export const getSearcherLastUpdated = (state) =>
  getSearcher(state).get('lastUpdated');
export const getSearcherIsCarouselDateFromRange = createSelector(
  [
    getSearchParamsStartDate,
    searchParamsPrimaryStartDateSelector,
    getSearcherCarouselDate,
  ],
  (startDate, flexStartDate, carouselDate) =>
    isDateBetweenRange({
      centerDate: flexStartDate || startDate || '',
      date: carouselDate,
    }),
);

export const getIsOpenFiltersList = (state) =>
  getSearcher(state).get('openFiltersList');
