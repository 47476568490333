import {
  getImmutableCompatible,
  getFromImmutableStateCompatible,
} from 'src/utils/immutableUtils';
import { createSelector } from '@reduxjs/toolkit';
import { getIsWhitelabel } from '../view/selectors';

export const _getCashbackData = (state) =>
  getImmutableCompatible(state, 'cashback') || {};

export const getCashbackData =
  getFromImmutableStateCompatible(_getCashbackData);

// Селектор для получения цвета фона лейбла кешбэка
export const getCashbackLabelBackgroundColor = createSelector(
  [getCashbackData],
  (cashback) => cashback?.data?.cashback?.label_background_color,
);

// Селектор для получения цвета текста кешбека
export const getCashbackLabelTextColor = createSelector(
  [getCashbackData],
  (cashback) => cashback?.data?.cashback?.label_text_color,
);

// Селектор для получения иконки кешбека в формате webp
export const getCashbackIconWebp = createSelector(
  [getCashbackData],
  (cashback) => cashback?.data?.cashback?.icon?.webp || null,
);

// Селектор для получения иконки кешбека в формате webp
export const getCashbackIconPng = createSelector(
  [getCashbackData],
  (cashback) => cashback?.data?.cashback?.icon?.png || null,
);

// Селектор для получения лейбла кешбека
export const getCashbackLabel = createSelector(
  [getCashbackData],
  (cashback) => cashback?.data?.cashback?.label,
);

// Селектор для получения текста тултипа кешбека
export const getCashbackTooltip = createSelector(
  [getCashbackData],
  (cashback) => cashback?.data?.cashback?.tooltip,
);

// Селектор для получения статуса активации кешбека для партнера
export const getActivateCashbackForPartner = createSelector(
  [getCashbackData, getIsWhitelabel],
  (cashback, isWhiteLabel) => {
    if (isWhiteLabel && cashback?.data?.cashback?.disabled) {
      return false;
    }
    return true;
  },
);
