import { i18n, supportedCurrency } from '@lt/localization';
import { enToRusKeyboardMap } from 'src/constants';
import { NBSP } from '../../constants/common';
import { PAYMENT_REDIRECT_PATH } from '../../constants/payment';
import { isSSR } from '../../helpers/ssr';

const DEFAULT_CODE = 'RUB';

export type CurrencyType = keyof typeof supportedCurrency;
export type CurrencySymbols =
  (typeof supportedCurrency)[keyof typeof supportedCurrency];

export const truncString = (str: string, max: number, add = '...'): string =>
  str.length > max ? str.substring(0, max) + add : str;

/**
 * Множественное число
 * @param {number} i
 * @param {string[]} variants
 * @returns {string}
 */
export const pluralize = (i: number, variants: readonly string[]): string => {
  const plural = (a) => {
    if (a % 10 === 1 && a % 100 !== 11) {
      return 0;
    }
    if (a % 10 >= 2 && a % 10 <= 4 && (a % 100 < 10 || a % 100 >= 20)) {
      return 1;
    }
    return 2;
  };

  switch (plural(i)) {
    case 0:
      return variants[0];
    case 1:
      return variants[1];
    default:
      return variants[2];
  }
};

export const numToWords = (num: number) => {
  if (num >= 1 && num <= 7) {
    return i18n.t('checkout.people', { context: `${num}` });
  }
  return `${num} ${i18n.t('checkout.peopleDefault')}`;
};

export const formatCurrency = (
  value: string | number,
  code: CurrencyType = DEFAULT_CODE,
): string => {
  const price = Number(value);
  /**
   * Intl не везде доступен (то ли в nodejs у нас какая-то трабла толи в браузерах)
   * Вот задача в которой фиксил: https://tracker.yandex.ru/LT-31774
   * Багов сыпалось очень много, так что не помешало бы разобраться
   * Но проверку не убирать для большей поддержки
   *
   * Лучше всего вынести либо @lt/localization, либо на худой случай в монолит
   */
  try {
    const formattedValue = new Intl.NumberFormat('ru-RU').format(price);

    return formattedValue.concat(NBSP, supportedCurrency[code]);
  } catch (err) {
    return `${price}${NBSP}${supportedCurrency[code]}`;
  }
};

/**
 * Форматирует цену в зависимости от названия валюты
 * @param {number} value
 * @param {string} currencyName
 * @returns {string}
 */
export const formatCurrencyWithName = (
  value: number,
  currencyName: string,
): string => {
  /**
   * Intl не везде доступен (то ли в nodejs у нас какая-то трабла толи в браузерах)
   * Вот задача в которой фиксил: https://tracker.yandex.ru/LT-31774
   * Багов сыпалось очень много, так что не помешало бы разобраться
   * Но проверку не убирать для большей поддержки
   *
   * Лучше всего вынести либо @lt/localization, либо на худой случай в монолит
   */
  try {
    return Intl.NumberFormat('ru-RU', {
      style: 'currency',
      currency: currencyName,
      minimumFractionDigits: 0,
    }).format(value);
  } catch (err) {
    return `${value}${currencyName}`;
  }
};

/**
 * Форматирует в компактном формате
 * @example 120000 -> 120K
 */
export const formatCurrencyCompact = (value: string | number): string => {
  const price = Number(value);
  /**
   * Intl не везде доступен (то ли в nodejs у нас какая-то трабла толи в браузерах)
   * Вот задача в которой фиксил: https://tracker.yandex.ru/LT-31774
   * Багов сыпалось очень много, так что не помешало бы разобраться
   * Но проверку не убирать для большей поддержки
   *
   * Лучше всего вынести либо @lt/localization, либо на худой случай в монолит
   */
  try {
    const formattedValue = new Intl.NumberFormat('en-US', {
      notation: 'compact',
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    }).format(price);

    return formattedValue;
  } catch (err) {
    return `${price}`;
  }
};

export const formatNumber = (value: number): string => {
  /**
   * Intl не везде доступен (то ли в nodejs у нас какая-то трабла толи в браузерах)
   * Вот задача в которой фиксил: https://tracker.yandex.ru/LT-31774
   * Багов сыпалось очень много, так что не помешало бы разобраться
   * Но проверку не убирать для большей поддержки
   *
   * Лучше всего вынести либо @lt/localization, либо на худой случай в монолит
   */
  try {
    return Intl.NumberFormat('ru-RU', {
      minimumFractionDigits: 0,
    }).format(value);
  } catch (err) {
    return String(value);
  }
};

/**
 * Форматирует номер телефона
 * @param {string} value
 * @returns {string} +7 (000) 000 00 00
 */
export const formatPhone = (value: string) =>
  value.replace(/\+?(\d)(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3 $4 $5');

/**
 * Loosely matches value to text.
 * Empty value is a match
 * TODO: use something like https://github.com/Glench/fuzzyset.js
 * @param {string} text
 * @param {string} value
 */
export const fuzzyMatch = (text: string, value: string) =>
  !value || (text && text.toLowerCase().indexOf(value.toLowerCase()) !== -1);

export const equalsIgnoreCase = (str1: string, str2: string) => {
  if (str1 === str2) {
    return true;
  }
  if (!str1 || !str2) {
    return false;
  }
  return str1.toLowerCase() === str2.toLowerCase();
};

/**
 * @param {string} name
 * @param {number} stars
 * @return {string}
 */
export const getRouletteHotelTitle = (name: string, stars: number): string =>
  `${name} (${i18n.t('checkout.rouletteHotel')}${NBSP}${stars}*)`;

export const getPaymentRedirectUrl = (): string => {
  if (isSSR) return '';

  return `${window.location.origin}${PAYMENT_REDIRECT_PATH}`;
};

export const convertLatinToRus = (str: string): string =>
  str
    .toLowerCase()
    .split('')
    .map((sym) => enToRusKeyboardMap.get(sym) || sym)
    .join('');

/**
 *  'ИВАНОВ' -> 'Иванов'
 */
export const putUpperCaseFirstLetterOnly = (str: string): string =>
  str ? str[0].toUpperCase() + str.slice(1).toLowerCase() : '';
