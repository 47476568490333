import { ButtonTs } from '@lt/components';
import { useTranslation } from '@lt/localization';
import React from 'react';
import styled, { css } from 'styled-components';
import { NPS_ERROR_COMPONENT_TEST_ID } from '../constants';

type Props = {
  isMobile: boolean;
  onReload: () => void;
};

const NpsError = (props: Props) => {
  const { isMobile, onReload } = props;
  const { t } = useTranslation();
  return (
    <StyledContainer
      $isMobile={isMobile}
      data-testid={NPS_ERROR_COMPONENT_TEST_ID}
    >
      <StyledErrorTitle>{t('nps.error.title')}</StyledErrorTitle>
      <StyledErrorDescription>
        {t('nps.error.description')}
      </StyledErrorDescription>
      <ButtonTs onClick={onReload}>{t('nps.error.retryButton')}</ButtonTs>
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  ${({ $isMobile }) =>
    $isMobile
      ? css`
          height: 358px;
          & > button {
            margin-top: 24px;
          }
        `
      : css`
          height: 228px;
          padding: 24px 0px;
        `}
  flex-direction: column;
  justify-content: center;
  align-items: center;
  * + * {
    margin-top: 16px;
  }
  & > button {
    background: transparent;
  }
`;

const StyledErrorTitle = styled.div`
  color: #17181a;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  width: 552px;
  margin: 0 24px;
`;

const StyledErrorDescription = styled.div`
  font-size: 14px;
  width: 211px;
  text-align: center;
`;

export default NpsError;
