import { combineReducers } from '@reduxjs/toolkit';

// global store
import menu from 'src/store/menu/slice';
import feedback from 'src/store/feedback/slices';
import client from 'src/store/client/reducers';
import headerWidget from 'src/store/headerWidget/reducers';
import wishlist from 'src/store/wishlist/slice';
import bannerAppData from 'src/store/appBanners/slice';
import enqueue from 'src/store/enqueue/reducers';

import headerBanner from 'src/store/headerBanner/slice';

import cashback from 'src/store/cashback/slice';
import viewReducer from './view/slice';
import homeDynamicDataSlice from './homeDynamicData/slice';
import countriesSlice from './countries/slice';
import linkedHotelsSlice from './linkedHotels/slice';
import narrativeCardsSlice from './narrativeCards/slice';
import popularDestinationsOffersSlice from './popularDestinationsOffers/slice';
import popularMetaregionsOffersSlice from './popularMetaregionsOffers/slice';
import homeDisplayFlags from './homeDisplayFlags/slice';
import nps from './nps/slice';

const rootReducer = combineReducers({
  menu,
  view: viewReducer.reducer,
  feedback,
  wishlist,
  nps,
  headerWidget,
  client,
  homeDynamicDataSlice: homeDynamicDataSlice.reducer,
  countries: countriesSlice.reducer,
  linkedHotels: linkedHotelsSlice.reducer,
  narrativeCards: narrativeCardsSlice.reducer,
  popularDestinationsOffers: popularDestinationsOffersSlice.reducer,
  popularMetaregionsOffers: popularMetaregionsOffersSlice.reducer,
  bannerAppData,
  homeDisplayFlags,
  headerBanner,
  enqueue,
  cashback,
});

export default rootReducer;
