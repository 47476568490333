import React, { useEffect } from 'react';

import { MobilePopupBottom, Link, ButtonTs } from '@lt/components';

import { i18n } from '@lt/localization';
import { BANNER_TYPE } from 'src/store/appBanners/constants';
import type { IMobileAppPopup } from './types';
import styles from './mobileAppPopup.module.css';
import { MOBILE_APP_TEST_ID } from './constants';
import AppInformation from '../components/AppInformation';
import CopyPromoCode from './components/CopyPromoCode/CopyPromoCode';

const MobileAppPopup = ({
  onClickButton,
  trackShow,
  rating = 5,
  reviews = '12 тыс.',
  linkToApp,
  visible,
  onClick,
}: IMobileAppPopup) => {
  useEffect(() => {
    trackShow(BANNER_TYPE.MOBILE_BANNER_TO_APP);
    // задезейблил так как trackShow это функция и ссылка на нее можем меняться
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MobilePopupBottom visible={visible} onClick={onClick}>
      <div className={styles.container} data-testid={MOBILE_APP_TEST_ID}>
        <div className={styles.dragHandle} />
        <AppInformation reviewsCountText={reviews} rating={rating} />

        <div className={styles.description}>
          {i18n.t('mobile_apps_banner.descriptionMobile')}
        </div>
        <CopyPromoCode />
        <p className={styles.descriptionNote}>
          {i18n.t('mobile_apps_banner.descriptionNote')}
        </p>
        <Link href={linkToApp} target="_blank" className={styles.link}>
          <ButtonTs
            onClick={onClickButton}
            variant="primary"
            className={styles.goToAppButton}
            size="large"
          >
            {i18n.t('mobile_apps_banner.openAppButton')}
          </ButtonTs>
        </Link>
      </div>
    </MobilePopupBottom>
  );
};

export default MobileAppPopup;
