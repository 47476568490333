import React from 'react';

import BurgerIcon from '@lt/components/icons-react/rebrand/Burger';
import styles from './mobileMenu.module.css';
import { MOBILE_BURGER_MENU_TEST_ID } from '../constants';

type Props = {
  openSidebar: () => void;
};

const MobileMenu = (props: Props) => {
  const { openSidebar } = props;

  return (
    <BurgerIcon
      className={styles.burger}
      onClick={openSidebar}
      data-testid={MOBILE_BURGER_MENU_TEST_ID}
    />
  );
};

export default MobileMenu;
