import React from 'react';

import { Image } from '@lt/components';
import { useTranslation } from '@lt/localization';

import backgroundImageFallback from 'src/images/homepage_background_image/homepage_background_image.png';
import backgroundImageSrc from 'src/images/homepage_background_image/homepage_background_image.webp';
import styles from './backroundImage.module.css';
import {
  BACKGROUND_IMAGE_CONTAINER_TEST_ID,
  BACKGROUND_IMAGE_SOURCE_TEST_ID,
  BACKGROUND_IMAGE_TEXT_TEST_ID,
} from './constants';

interface BackgroundImageProps {
  isShowTitle: boolean;
  backgroundImage?: string;
}

const BackgroundImage = (props: BackgroundImageProps): JSX.Element => {
  const { isShowTitle, backgroundImage } = props;
  const { t } = useTranslation();
  return (
    <div
      className={styles.backgroundImageContainer}
      data-testid={BACKGROUND_IMAGE_CONTAINER_TEST_ID}
    >
      {!isShowTitle && (
        <span
          className={styles.title}
          data-testid={BACKGROUND_IMAGE_TEXT_TEST_ID}
        >
          {t('homeHeader.backgroundTitle')}
        </span>
      )}
      <Image
        className={styles.backgroundImage}
        src={backgroundImage || backgroundImageSrc}
        fallback={backgroundImageFallback}
        fetchpriority="high"
        data-testid={BACKGROUND_IMAGE_SOURCE_TEST_ID}
      />
    </div>
  );
};

export default BackgroundImage;
