import type { FC } from 'react';
import React from 'react';
import classnames from 'classnames';

import LtLogo, { PartnerLogo } from '@lt/components/components/Logo';
import { isSSR } from 'src/helpers/ssr';
import type { PartnerLogo as IPartnerLogo } from '../../../../store/menu/types';
import styles from './headerLogo.module.css';

interface Props {
  isMobile: LT.isMobile;
  isNewYear: boolean;
  isBlack: boolean;
  partnerLogo?: IPartnerLogo | null;
  inWidget?: boolean;
  isHomepage?: boolean;
  isWhiteLabel: boolean;
  className?: string;
}

const HeaderLogo: FC<Props> = ({
  partnerLogo,
  isMobile,
  isNewYear,
  inWidget,
  isBlack,
  isHomepage,
  isWhiteLabel,
  className,
}) => {
  if (isSSR && isWhiteLabel) return null;

  if (partnerLogo)
    return (
      <PartnerLogo
        src={partnerLogo.src}
        srcSet={partnerLogo.srcset}
        isMobile={isMobile}
        maxWidth={isHomepage ? 500 : 200}
        className={className}
      />
    );

  return (
    <div
      className={classnames(className, {
        [styles.logoWrapper]: isMobile && inWidget,
      })}
    >
      <LtLogo
        className={classnames({
          [styles.homepageLtLogo]: isHomepage && !isBlack,
          [styles.widgetLtLogo]: inWidget && isMobile && !isBlack,
          [styles.mobileLtLogo]: !inWidget && isMobile && !isBlack,
          [styles.defaultLtLogo]: !inWidget && !isMobile && !isBlack,
        })}
        isNewYear={isNewYear}
        isCompact={!inWidget && !isBlack && !isHomepage}
      />
    </div>
  );
};

export default HeaderLogo;
