export const CIGARETTE_FILTER_TEST_ID = 'CIGARETTE_FILTER_TEST_ID';

export const CIGARETTE_DESKTOP_PREVIEW_TEST_ID =
  'CIGARETTE_DESKTOP_PREVIEW_TEST_ID';
export const CIGARETTE_WIZARD_PREVIEW_TEST_ID =
  'CIGARETTE_WIZARD_PREVIEW_TEST_ID';

export const DESTINATION_PICKER_PREVIEW_TEST_ID =
  'DESTINATION_PICKER_PREVIEW_TEST_ID';
export const DATE_PICKER_PREVIEW_TEST_ID = 'DATE_PICKER_PREVIEW_TEST_ID';
export const TOURISTS_PICKER_PREVIEW_TEST_ID =
  'TOURISTS_PICKER_PREVIEW_TEST_ID';
export const DEPARTURE_PICKER_PREVIEW_TEST_ID =
  'DEPARTURE_PICKER_PREVIEW_TEST_ID';
export const CIGARETTE_SUBMIT_BUTTON_PREVIEW_TEST_ID =
  'CIGARETTE_SUBMIT_BUTTON_PREVIEW_TEST_ID';

export const CIGARETTE_CONTROL_PREVIEW_TEST_IDS = {
  destination: DESTINATION_PICKER_PREVIEW_TEST_ID,
  datepicker: DATE_PICKER_PREVIEW_TEST_ID,
  tourists: TOURISTS_PICKER_PREVIEW_TEST_ID,
  departure: DEPARTURE_PICKER_PREVIEW_TEST_ID,
  submit: CIGARETTE_SUBMIT_BUTTON_PREVIEW_TEST_ID,
};

export const CIGARETTE_SKELETON_TEST_ID = 'CIGARETTE_SKELETON_TEST_ID';
