import classnames from 'classnames';
import React from 'react';

import type { ICardHeading } from './types';
import styles from './cardHeading.module.css';

export const CardHeading = (props: ICardHeading) => {
  const { text, url, isMobile, isBlack = false, hasMarginTop = false } = props;

  return (
    <a
      href={url}
      className={classnames(styles.cardHeadingLink, styles.cardHeadingText, {
        [styles.cardHeadingTextWithMargin]: hasMarginTop,
        [styles.cardHeadingTextMobile]: isMobile,
        [styles.cardHeadingTextWithMarginMobile]: isMobile && hasMarginTop,
        [styles.cardHeadingTextBlack]: isBlack,
      })}
    >
      {text}
    </a>
  );
};
