import { connect, type ConnectedProps } from 'react-redux';
import { getIsMobile } from 'src/store/view/selectors';
import NavigationLinks from './NavigationLinks';

const mapStateToProps = (state) => ({
  isMobile: getIsMobile(state),
});

const connector = connect(mapStateToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NavigationLinks);
