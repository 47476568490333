import type { PayloadAction, Draft } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { ChangeOnSliderData } from 'src/components/Filters/components/FilterOneSlider/types';
import type { ChangeSliderFilterPayload } from 'src/components/Filters/components/FilterSlider/types';
import { nestedFilters, sliderFilters } from './constants';
import type {
  FlightsFilter,
  FlightsFilters,
  ChangeFilterPayload,
  ClearFilterPayload,
  FlightsFiltersSlice,
} from './types';

import {
  transformResponse,
  unwrapIfNestedFilter,
  filterEmptyFilters,
  getTOFiltersFromUrl,
} from './utils';

export const name = 'flightsFilters';

// Фильтры по полетам на первой странице чекаута

export const initialState: FlightsFiltersSlice = {
  isInitialFlightsFetchingDone: false,
  filters: {
    arrival_airports: { airports: [], airportCities: [] },
    backward_airlines: [],
    departure_airports: { airports: [], airportCity: '' },
    flight_classes: [],
    flight_types: [],
    forward_airlines: [],
    luggage_types: [],
    transitions_count: [],
    transitions_time: [],
    operators: [],
    forward_arrival_dates: [],
    max_flight_duration: {
      suitable_flights: 0,
      min_fuel_charge: 0,
      min_duration: 0,
      max_duration: 0,
      selected_duration: undefined,
    },
    forward_departure_time: {
      suitable_flights: 0,
      min_fuel_charge: 0,
      time_from: undefined,
      time_to: undefined,
    },
    backward_departure_time: {
      suitable_flights: 0,
      min_fuel_charge: 0,
      time_from: undefined,
      time_to: undefined,
    },
  },
  preselectedFilters: {
    operators: getTOFiltersFromUrl(),
  },
};

// reducer + actions
const flightsFilters = createSlice({
  name,
  initialState,
  reducers: {
    setFlightsFilters(
      state: Draft<FlightsFiltersSlice>,
      action: PayloadAction<FlightsFilters>,
    ) {
      const { payload } = action;
      state.filters = transformResponse(payload);
      return state;
    },

    setSpecificFilter(
      state,
      action: PayloadAction<{ filterName: string; filter: FlightsFilter[] }>,
    ) {
      const { filterName, filter } = action.payload;
      state.filters[filterName] = filter;
    },

    changeFlightsFilter(
      state: Draft<FlightsFiltersSlice>,
      action: PayloadAction<ChangeFilterPayload>,
    ) {
      const { path, diff } = action.payload;
      const { id } = diff;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { name: _, ...newOptionState } = diff;

      const filterDataToChange = unwrapIfNestedFilter(state.filters, path);

      const filterOptionToChange = filterDataToChange.find(
        (filterOption: FlightsFilter) => filterOption.id === id,
      );

      if (filterOptionToChange) {
        const fieldsToChange = Object.entries(newOptionState);
        fieldsToChange.forEach(([fieldName, fieldValue]) => {
          filterOptionToChange[fieldName] = fieldValue;
        });
      }

      if (state.preselectedFilters[path]) {
        state.preselectedFilters[path] = state.preselectedFilters[path].filter(
          (el) => el !== filterOptionToChange?.id,
        );
      }

      return state;
    },

    changeFlightsSliderFilter(
      state: Draft<FlightsFiltersSlice>,
      action: PayloadAction<ChangeSliderFilterPayload>,
    ) {
      const { path, selected_time_from, selected_time_to } = action.payload;
      if (state.filters[path].selected_time_from !== selected_time_from)
        state.filters[path].selected_time_from = selected_time_from;
      if (state.filters[path].selected_time_to !== selected_time_to)
        state.filters[path].selected_time_to = selected_time_to;
    },

    changeFlightsOneSliderFilter(
      state: Draft<FlightsFiltersSlice>,
      action: PayloadAction<ChangeOnSliderData>,
    ) {
      const { path, selected_duration } = action.payload;
      if (state.filters[path].selected_duration !== selected_duration)
        state.filters[path].selected_duration = selected_duration;
    },

    setUnfilteredFlightsCount(
      state: Draft<FlightsFiltersSlice>,
      action: PayloadAction<number>,
    ) {
      state.unfilteredFlightsCount = action.payload;
    },

    clearFlightsFilter(
      state: Draft<FlightsFiltersSlice>,
      action: PayloadAction<ClearFilterPayload>,
    ) {
      const { payload } = action;

      if (
        payload === 'forward_departure_time' ||
        payload === 'backward_departure_time'
      ) {
        state.filters[payload].selected_time_from = undefined;
        state.filters[payload].selected_time_to = undefined;
      }

      if (payload === 'max_flight_duration') {
        state.filters[payload].selected_duration = undefined;
      }
      const filterDataToUpdate = unwrapIfNestedFilter(state.filters, payload);

      if (state.preselectedFilters[payload]) {
        state.preselectedFilters[payload] = [];
      }

      if (!sliderFilters.includes(payload))
        filterDataToUpdate.forEach((filterOption) => {
          filterOption.selected = false;
        });
      return state;
    },

    clearAllFligthsFilters(state: Draft<FlightsFiltersSlice>) {
      const cleanFilters = Object.fromEntries(
        Object.entries(state.filters)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .filter(filterEmptyFilters as any)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((filter: any) => {
            const [filterName] = filter;

            if (
              filterName === 'forward_departure_time' ||
              filterName === 'backward_departure_time'
            ) {
              state.filters[filterName].selected_time_from = undefined;
              state.filters[filterName].selected_time_to = undefined;
            }

            if (filterName === 'max_flight_duration') {
              state.filters[filterName].selected_duration = undefined;
            }

            const filterDataToUpdate = unwrapIfNestedFilter(
              state.filters,
              filterName,
            );

            const updatedFilterOptions = filterDataToUpdate.map(
              (filterOption) => ({
                ...filterOption,
                selected: false,
              }),
            );

            if (nestedFilters.includes(filterName))
              return [
                filterName,
                { ...filterDataToUpdate, airports: updatedFilterOptions },
              ];
            return [filterName, updatedFilterOptions];
          }),
      );

      state.filters = cleanFilters;
      state.preselectedFilters = { operators: [] };
      return state;
    },

    setIsInitialFlightsFetchingDone(
      state: Draft<FlightsFiltersSlice>,
      action: PayloadAction<boolean>,
    ) {
      state.isInitialFlightsFetchingDone = action.payload;
    },
  },
});

export const {
  setFlightsFilters,
  changeFlightsFilter,
  clearFlightsFilter,
  clearAllFligthsFilters,
  setUnfilteredFlightsCount,
  setIsInitialFlightsFetchingDone,
  setSpecificFilter,
  changeFlightsSliderFilter,
  changeFlightsOneSliderFilter,
} = flightsFilters.actions;

export default flightsFilters.reducer;
