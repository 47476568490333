import React from 'react';
import { Provider } from 'react-redux';

import UserflowTheme from '@lt/components/themes/userflow';

import ErrorBoundary from 'src/error/components/ErrorBoundary';

import HomeApp from '../Home/HomeApp';

// Global Styles
// TODO: Remove when use Layout
import 'src/components/Layout/styles/index.scss';
import '../../global.css';

import { getStore } from '../Home/store';

import type { ICountry } from '../Home/components/CountriesList/types';
import type { IHomeDisplayFlags } from '../Home/store/homeDisplayFlags/types';
import type { ILinkedHotel } from '../Home/store/linkedHotels/types';
import type { INarrativeCard } from '../Home/store/narrativeCards/types';
import type { IPopularDestinationsOffer } from '../Home/store/popularDestinationsOffers/types';
import type { IPopularMetaregionsOffer } from '../Home/store/popularMetaregionsOffers/types';

export interface AppProps {
  isMobile: boolean;
  isWhitelabel: boolean;
  platform: string;
  countries: ICountry[];
  homeDisplayFlags: IHomeDisplayFlags;
  linkedHotels?: ILinkedHotel[] | null;
  narrativeCards?: INarrativeCard[] | null;
  popularDestinationsOffers?: IPopularDestinationsOffer[] | null;
  popularMetaregionsOffers?: IPopularMetaregionsOffer[] | null;
  isAppBannerEnabled: boolean;
}

const app = (props: AppProps) => {
  const { isMobile, platform } = props;

  return (
    <ErrorBoundary>
      <Provider store={getStore(props)}>
        <UserflowTheme>
          <HomeApp isMobile={isMobile} platform={platform} isModernDesktop />
        </UserflowTheme>
      </Provider>
    </ErrorBoundary>
  );
};

export default app;
