/* eslint-disable prettier/prettier */
import { i18n } from '@lt/localization';
import type { NavigationBlock } from './types';

export const navigationBlocks: NavigationBlock[] = [
  {
    title: i18n.t('footer.company.title'),
    items: [
      { links: [{ href: '/about', text: i18n.t('footer.company.aboutUs') }] },
      {
        links: [
          {
            href: 'https://career.level.travel/',
            text: i18n.t('footer.company.career'),
          },
        ],
      },
      {
        links: [
          { href: '/testimonials', text: i18n.t('footer.company.reviews') },
        ],
      },
      {
        links: [
          {
            href: 'https://press.level.travel/',
            text: i18n.t('footer.company.press'),
          },
        ],
      },
      {
        links: [{ href: '/contacts', text: i18n.t('footer.company.contacts') }],
      },
      {
        links: [
          {
            href: 'https://promo.level.travel/co-promo',
            text: i18n.t('footer.company.coPromo'),
          },
        ],
      },
    ],
  },
  {
    title: i18n.t('footer.instruments.title'),
    items: [
      {
        links: [
          {
            href: 'https://promo.level.travel/calendar',
            text: i18n.t('footer.instruments.calendar'),
          },
        ],
      },
      {
        links: [
          {
            href: '/gift',
            text: i18n.t('footer.instruments.giftCertificates'),
          },
        ],
      },
      {
        links: [
          {
            href: 'https://promo.level.travel/paylate',
            text: i18n.t('footer.instruments.installment'),
          },
        ],
      },
      {
        links: [
          {
            href: 'https://promo.level.travel/travelpartners',
            text: i18n.t('footer.instruments.partnership'),
          },
        ],
      },
      {
        links: [
          {
            href: 'https://level.travel/media/index.php',
            text: i18n.t('footer.instruments.travelJournal'),
          },
        ],
      },
    ],
  },
  {
    title: i18n.t('footer.help.title'),
    items: [
      {
        links: [
          {
            href: 'https://promo.level.travel/how-to-buy',
            text: i18n.t('footer.help.howToBookTour'),
          },
        ],
      },
      {
        links: [
          {
            href: 'https://customer.level.travel/countries',
            text: i18n.t('footer.help.rulesAndVisa'),
          },
        ],
      },
      {
        links: [
          {
            href: 'https://help.level.travel/',
            text: i18n.t('footer.help.Q&A'),
          },
        ],
      },
      {
        links: [
          {
            href: 'https://promo.level.travel/',
            text: i18n.t('footer.help.promo'),
          },
        ],
      },
    ],
  },
  {
    title: i18n.t('footer.hotelsTitle'),
    items: [
      {
        links: [
          { href: '/tophotels/any/Russia', text: 'Россия: ' },
          { href: '/tophotels/any/Sochi-Russia', text: 'Сочи, ' },
          { href: '/tophotels/any/Adler-Russia', text: 'Адлер, ' },
          { href: '/tophotels/any/St_Petersburg-Russia', text: 'СПб, ' },
          { href: '/tophotels/any/Moscow-Russia', text: 'Москва' },
        ],
      },
      {
        links: [
          { href: '/tophotels/any/Turkey', text: 'Турция: ' },
          { href: '/tophotels/any/Istanbul-Turkey', text: 'Стамбул, ' },
          { href: '/tophotels/any/Antalya-Turkey', text: 'Анталья, ' },
          { href: '/tophotels/any/Alanya-Turkey', text: 'Алания' },
        ],
      },
      {
        links: [
          { href: '/tophotels/any/Thailand', text: 'Таиланд: ' },
          { href: '/tophotels/any/Phuket-Thailand', text: 'Пхукет, ' },
          { href: '/tophotels/any/Pattaya-Thailand', text: 'Паттайя' },
        ],
      },
      {
        links: [
          { href: '/tophotels/any/Egypt', text: 'Египет: ' },
          { href: '/tophotels/any/Hurghada-Egypt', text: 'Хургада, ' },
          {
            href: '/tophotels/any/Sharm_el_Sheikh-Egypt',
            text: 'Шарм-Эль-Шейх',
          },
        ],
      },
      {
        links: [
          { href: '/tophotels/any/UAE', text: 'ОАЭ: ' },
          { href: '/tophotels/any/Dubai-UAE', text: 'Дубай, ' },
          { href: '/tophotels/any/Sharjah-UAE', text: 'Шарджа' },
        ],
      },
      {
        links: [
          { href: '/tophotels/any/Maldives', text: 'Мальдивы: ' },
          { href: '/tophotels/any/Male-Maldives', text: 'Мале, ' },
          { href: '/tophotels/any/Maafushi-Maldives', text: 'Маафуши' },
        ],
      },
      {
        links: [
          { href: '/tophotels/any/Sri_Lanka', text: 'Шри-Ланка: ' },
          { href: '/tophotels/any/Hikkaduwa-Sri_Lanka', text: 'Хиккадува' },
        ],
      },
      {
        links: [
          { href: '/tophotels/any/India', text: 'Индия: ' },
          { href: '/tophotels/any/Goa-India', text: 'Гоа' },
        ],
      },
    ],
  },
  {
    title: i18n.t('footer.toursTitle'),
    items: [
      {
        links: [{ href: '/explore/Moscow-Russia/any/anex_tour', text: 'Anex' }],
      },
      {
        links: [
          {
            href: '/explore/Moscow-Russia/any/biblio_globus',
            text: 'Biblio Globus',
          },
        ],
      },
      {
        links: [
          {
            href: '/explore/Moscow-Russia/any/coral_travel',
            text: 'Coral Travel',
          },
        ],
      },
      {
        links: [
          {
            href: '/explore/Moscow-Russia/any/leveltravel',
            text: 'Level.Travel',
          },
        ],
      },
      {
        links: [
          { href: '/explore/Moscow-Russia/any/pegas', text: 'Pegas Touristik' },
        ],
      },
      {
        links: [{ href: '/explore/Moscow-Russia/any/funsun', text: 'Fun&Sun' }],
      },
      {
        links: [{ href: '/explore/Moscow-Russia/any/sunmar', text: 'Sunmar' }],
      },
      {
        links: [
          { href: '/explore/Moscow-Russia/any/tez_tour', text: 'Tez Tour' },
        ],
      },
      { links: [{ href: '/explore/Moscow-Russia/any/alean', text: 'Алеан' }] },
    ],
  },
];
