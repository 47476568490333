import { useEffect, useReducer } from 'react';

// Принудительно перерендериваем компонент, чтобы избежать сломанной верстки
export const useForceRender = () => {
  const [countForceRender, forceRender] = useReducer((count) => count + 1, 0);
  useEffect(() => {
    forceRender();
  }, []);

  return { countForceRender };
};
