import React from 'react';
import { Cashback } from '@lt/components/icons-react/rebrand';
import styles from './HeaderCashbackComponent.module.css';

const HeaderCashbackComponent = ({ bonusScore }) => (
  <div className={styles.infoWrapper}>
    <p>{bonusScore}</p>
    <Cashback width={14} height={14} />
  </div>
);

export default HeaderCashbackComponent;
