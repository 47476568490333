export const SEARCH_TAB_TOURS_DATA_TEST_ID = 'SEARCH_TAB_TOURS_DATA_TEST_ID';
export const SEARCH_TAB_HOTELS_DATA_TEST_ID = 'SEARCH_TAB_HOTELS_DATA_TEST_ID';
export const SEARCH_TAB_EXCURSIONS_DATA_TEST_ID =
  'SEARCH_TAB_EXCURSIONS_DATA_TEST_ID';

export const TABS_PAGES = {
  SEARCH: 'SEARCH',
  MAIN: 'MAIN',
  OLD_MAIN: 'OLD_MAIN',
  HOTEL: 'HOTEL',
} as const;

export const TABS_STYLES = {
  DESKTOP: {
    MAIN_PAGE: {
      ENABLED: {
        TAB: `
        color: var(--white);
        background: var(--black);
        font-size: 14px;
        font-weight: 500;
      `,
        ICON: `fill: var(--white);`,
      },
      DISABLED: {
        TAB: `
        color: var(--white);
        background: transparent;
        font-size: 14px;
        font-weight: 500;
      `,
        ICON: `fill: var(--white);`,
      },
    },
    SEARCH: {
      ENABLED: {
        TAB: `
        color: var(--orange);
        background: var(--white);
        font-size: 14px;
        font-weight: 500;
      `,
        ICON: `fill: var(--orange);`,
      },
      DISABLED: {
        TAB: `
        color: var(--black);
        background: transparent;
        font-size: 14px;
        font-weight: 500;
      `,
        ICON: ``,
      },
    },
    HOTEL: {
      ENABLED: {
        TAB: `
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        color: var(--orange);
        background-color: var(--background);
      `,
        ICON: `fill: var(--orange);`,
      },
      DISABLED: {
        TAB: `
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        background: transparent;
      `,
        ICON: ``,
      },
    },
  },
  MOBILE: {
    MAIN_PAGE: {
      OLD: {
        ENABLED: {
          TAB: `
          color: var(--white);
          background-color: var(--black);
        `,
          ICON: `fill: var(--white);`,
        },
        DISABLED: {
          TAB: `
          color: var(--white);
          background: transparent;
        `,
          ICON: `fill: var(--white);`,
        },
      },
      NEW: {
        ENABLED: {
          TAB: `
          color: var(--black);
          background: var(--white);
        `,
          ICON: ``,
        },
        DISABLED: {
          TAB: `
          color: white;
          background: transparent;
        `,
          ICON: `fill: var(--white)`,
        },
      },
    },
    SEARCH: {
      COLLAPSED: {
        ENABLED: {
          TAB: `
          color: var(--white);
          background-color: var(--black);
        `,
          ICON: `fill: var(--white);`,
        },
        DISABLED: {
          TAB: `
          color: var(--white);
          background: transparent;
        `,
          ICON: `fill: var(--white);`,
        },
      },
      EXPANDED: {
        ENABLED: {
          TAB: `
          color: var(--orange);
          background-color: var(--white);
        `,
          ICON: `fill: var(--orange);`,
        },
        DISABLED: {
          TAB: `
          color: var(--black);
          background: transparent;
        `,
          ICON: ``,
        },
      },
    },
    HOTEL: {
      ENABLED: {
        TAB: `
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        color: var(--orange);
        background-color: var(--background);
      `,
        ICON: `fill: var(--orange);`,
      },
      DISABLED: {
        TAB: `
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        background: transparent;
      `,
        ICON: ``,
      },
    },
  },
} as const;

export const WL_TABS_STYLES = {
  DESKTOP: {
    MAIN_PAGE: {
      ENABLED: {
        TAB: `
        color: var(--white);
        background: var(--black);
        font-size: 14px;
        font-weight: 500;
      `,
        ICON: `fill: var(--white);`,
      },
      DISABLED: {
        TAB: `
        color: var(--white);
        background: transparent;
        font-size: 14px;
        font-weight: 500;
      `,
        ICON: `fill: var(--white);`,
      },
    },
    SEARCH: {
      ENABLED: {
        TAB: `
        color: var(--orange);
        background: var(--white);
        font-size: 14px;
        font-weight: 500;
      `,
        ICON: `fill: var(--orange)`,
      },
      DISABLED: {
        TAB: `
        color: var(--white);
        background: transparent;
        font-size: 14px;
        font-weight: 500;
      `,
        ICON: `fill: var(--white)`,
      },
    },
    HOTEL: {
      ENABLED: {
        TAB: `
        color: var(--orange);
        font-weight: 500;
        color: black;
        background: var(--white);
      `,
        ICON: `fill: var(--orange)`,
      },
      DISABLED: {
        TAB: `
        font-weight: 500;
        color: var(--white);
        background: transparent;
      `,
        ICON: `fill: var(--white)`,
      },
    },
  },
  MOBILE: {
    MAIN_PAGE: TABS_STYLES.MOBILE.MAIN_PAGE,
    SEARCH: {
      COLLAPSED: TABS_STYLES.MOBILE.SEARCH.COLLAPSED,
      EXPANDED: {
        ENABLED: {
          TAB: `
          color: var(--orange);
          background-color: var(--white);
        `,
          ICON: `fill: var(--orange);`,
        },
        DISABLED: {
          TAB: `
          color: var(--white);
          background: transparent;
        `,
          ICON: `fill: var(--white);`,
        },
      },
    },
    HOTEL: TABS_STYLES.MOBILE.HOTEL,
  },
} as const;
