import { connect } from 'react-redux';
import { getClientBonusScore } from 'src/store/client/selectors';
import {
  getCashbackIconPng,
  getCashbackIconWebp,
} from 'src/store/cashback/selectors';
import HeaderCashback from './HeaderCashback';

const mapStateToProps = () => (state) => ({
  bonusScore: getClientBonusScore(state),
  cashbackIconWebp: getCashbackIconWebp(state),
  cashbackIconPng: getCashbackIconPng(state),
});

export default connect(mapStateToProps)(HeaderCashback);
