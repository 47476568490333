import { connect, type ConnectedProps } from 'react-redux';
import { getShortLink } from 'src/store/appBanners/selectors';
import { getDeeplinkAction } from 'src/store/appBanners/slice';
import { getIsMobile } from 'src/store/view/selectors';
import DownloadApp from './DownloadApp';

const mapStateToProps = (state) => ({
  appLink: getShortLink(state),
  isMobile: getIsMobile(state),
});

const mapDispatchToProps = {
  getLink: getDeeplinkAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DownloadApp);
