import { formatCurrency } from '../../utils/stringUtils';

export const PAYMENT_METHOD_CARD = 'card';
export const PAYMENT_METHOD_PREPAYMENT = 'prepayment';
export const PAYMENT_METHOD_CUSTOM_PREPAYMENT = 'custom_prepayment';
export const PAYMENT_METHOD_CASH = 'cash';
export const PAYMENT_METHOD_PAYLATE = 'paylate';
export const PAYMENT_METHOD_PAYLATE_120 = 'paylate_120';
export const PAYMENT_METHOD_MIR_CARD = 'mir_cashback';
export const PAYMENT_METHOD_MIR_CARD_PARTNER = 'mir_cashback_partner';
export const PAYMENT_METHOD_PAY_LATER = 'pay_later';

export const PAYMENT_GROUPS = {
  CARD: 'card',
  PAYLATE: 'paylate',
  PAY_LATER: 'pay_later',
} as const;

export const PAYMENT_ERRORS = {
  UNKNOWN_ERROR: 'Произошла неизвестная ошибка',
  PAY_ERROR: 'Произошла ошибка оплаты',
  THREE_DS: 'Не удалось получить ответ от банка при проверке 3-D Secure',
  PAY_CARD: 'Произошла ошибка оплаты',
  PAYMENT_CREATION: 'Не удалось создать платеж',
  SBP: 'Не удалось получить параметры для СБП',
  PAYLATE: 'Не удалось получить параметры для Paylate',
  TBANK_CREDIT: 'Произошла ошибка во время оформления тбанк рассрочки',
  T_PAY: 'Произошла ошибка во время оформления тбанк рассрочки',
};

export const CARD_FIELDS = {
  NUMBER: {
    id: 'pan',
    name: 'pan',
    mask: '9999 9999 9999 9999999',
    placeholder: '0000 0000 0000 0000',
    autocomplete: 'cc-number',
  },
  EXP_DATE: {
    id: 'expDate',
    name: 'expDate',
    mask: '99/99',
    placeholder: '00/00',
    autocomplete: 'cc-exp',
  },
  CVV: {
    id: 'cvv',
    name: 'cvv',
    mask: '999',
    placeholder: '000',
    autocomplete: 'cc-csc',
  },
};

export const PAYLATE_WARNING_AMOUNT = 420000;
export const PAYLATE_WARNING = `Обратите внимание, что максимальная сумма кредита -
${formatCurrency(
  PAYLATE_WARNING_AMOUNT,
)}. Оставшуюся часть нужно будет заплатить самостоятельно`;

export const PAY_ORDER = 'PAY_ORDER';
