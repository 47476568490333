import React from 'react';
import cn from 'classnames';

import { Link } from '@lt/components';
import { i18n } from '@lt/localization';
import SkolkovoIcon from '@lt/components/icons-react/rebrand/Skolkovo';

import type { PropsFromRedux as Props } from '.';
import styles from './legalInfo.module.css';

const LegalInfo = ({ isMobile = false }: Props) => {
  const textClassName = cn(styles.text, { [styles.mobileText]: isMobile });
  const linkClassName = cn(textClassName, styles.link);

  return (
    <div
      className={cn(styles.container, { [styles.mobileContainer]: isMobile })}
    >
      {/* Реквизиты */}
      <div>
        <p className={textClassName}>
          {i18n.t('footer.legalInformation.levelTravel', {
            currentYear: new Date().getFullYear(),
          })}
        </p>
        <p className={textClassName}>
          {i18n.t('footer.legalInformation.unigate')}
        </p>
        <p className={textClassName}>
          {i18n.t('footer.legalInformation.priceInfo')}
        </p>
      </div>
      {/* Памятка */}
      <p className={textClassName}>
        {i18n.t('footer.legalInformation.infoDisclaimer')}
      </p>
      {/* Ссылки */}
      <div className={styles.links}>
        <Link className={linkClassName} href="/agreements">
          {i18n.t('footer.legalInformation.furtherDetailsLink')}
        </Link>
        <br />
        <Link
          className={linkClassName}
          href="/articles/Privacy_Policy_Level_Travel"
        >
          {i18n.t('footer.legalInformation.privacyPolicy')}
        </Link>
      </div>
      {/* Иконки */}
      <Link href="https://sk.ru">
        <SkolkovoIcon width={150} />
      </Link>
    </div>
  );
};

export default LegalInfo;
