import { createSelector } from '@reduxjs/toolkit';
import { differenceInDays } from 'date-fns';
import { filter, isEmpty, isNil, pick, trim } from 'lodash';

import { i18n } from '@lt/localization';
import { touristsSelector } from 'src/store/tourists/selectors';
import type { TouristsState } from 'src/store/tourists/slice';
import { getIsMobile } from 'src/store/view/selectors';
import { CHANGING_DAYS_LIMIT } from '../../../../components/Tourists/contstants';

// Selector:
import {
  getCardIsValid,
  getPaymentData,
  getPaymentMethod,
} from '../../../../store/payment/selectors';
import {
  allFlightsSelector,
  cheapestFlightSelector,
  minFuelChargeSelector,
} from '../../CheckoutRoutes/PackageCheckout/store/flights/selectors';
import { getIsPrebookInProgress } from '../domains/package-prebook/selectors';
import { getNetPrice, getTransferInfo, isLTOperatorSelector } from './package';

// Contants:
import { PAYMENT_GROUPS, PAY_ORDER } from '../../../../store/payment/constants';
import { DISCOUNT_BONUS } from '../constants/discount';
import { DOC_TYPES, TOURIST_FIELDS } from '../constants/tourists';

import type {
  FlightsData,
  TransformedBackFlight,
} from '../../CheckoutRoutes/PackageCheckout/types/flights';
import type { PriceDetailType } from '../../components/Summary/types';
import { getPaymentGroup } from '../../utils/orderUtils';
import type { OrderStateType } from '../order/types';
import { getErrorAction } from '../../../../store/error/selectors';

// Возвращает подготовленную информацию о туристах для запроса
const getTouristInfo = (data: TouristsState) => {
  const { hideDocInputs, docType } = data;
  const omittedFields = [
    TOURIST_FIELDS.DOC_TYPE, // API v3 doesnt take docType
    ...(hideDocInputs
      ? [TOURIST_FIELDS.DOC_NUMBER, TOURIST_FIELDS.DOC_EXPIRES_DATE]
      : []),
  ];
  const fields = Object.values(TOURIST_FIELDS).filter(
    (filedName) =>
      omittedFields.findIndex((value) => value === filedName) === -1,
  );

  const touristInfo = pick(data, fields);
  if (hideDocInputs) {
    // HACK FOR v3: set doc_number as 'default' when user chooses not to fill doc number
    touristInfo[TOURIST_FIELDS.DOC_NUMBER] =
      DOC_TYPES[docType].numberField.placeholder;
  }

  // эта зачем-то для объекта на каждое значение применяет trim.
  // сложно непонятно, но вроде работает
  return Object.keys(touristInfo).reduce((result, key) => {
    const trimmedValue = trim(touristInfo[key]);
    result[key] = trimmedValue;

    return result;
  }, {});
};

export const getSource = createSelector(getIsMobile, (isMobile) =>
  isMobile ? 'mobile' : 'desktop',
);

const getOrder = (state): OrderStateType => state.order;

export const getOrderClientConfirmed = createSelector(
  getOrder,
  (orderState) => orderState.isClientConfirmed,
);

export const getOrderTouristsConfirmed = createSelector(
  getOrder,
  (orderState) => orderState.isTouristsConfirmed,
);

export const getOrderFlight = createSelector(
  getOrder,
  (orderState) => orderState.flight_pair_id || undefined,
);

export const isTouristsAvailable = (state) => getIsFirstStepDone(state);

export const getOrderTourists = createSelector(
  [touristsSelector, getOrderTouristsConfirmed],
  (tourists, isConfirmed) => {
    if (!isConfirmed || isEmpty(tourists)) return null;

    return tourists.map(getTouristInfo);
  },
);

// FLIGHTS:
/**
 * Проверяет был ли пакет создан вручную, если да, то пропускаем выбор рейсов
 * @param {reducer} state Order reducer
 * @returns true, если пакет был создан вручную, в остальных случаях – false
 */
export const getIsSkipFlights = createSelector(
  getOrder,
  (orderState) => orderState.isSkipFlights,
);

/**
 * Возвращает объект данных перелета туда и обратно
 */
export const getSelectedFlightData = createSelector(
  [allFlightsSelector, getOrderFlight],
  (flights, selectedFlightId): FlightsData | null => {
    if (flights && !!flights.length && selectedFlightId) {
      let selectedBack: unknown = null;

      const flight = flights.find((item) => {
        const { back } = item;

        if (back && !!back.length) {
          return back.find((itemBack) => {
            const isSelected = itemBack.flight_pair_id === selectedFlightId;
            if (isSelected) {
              selectedBack = itemBack;
            }
            return isSelected;
          });
        }

        return null;
      });

      if (!selectedBack || !flight) {
        return null;
      }

      return {
        to: flight.to,
        back: [selectedBack] as TransformedBackFlight[],
        extras: flight.extras,
        isCheapest: flight.isCheapest,
        isCheapestDirect: flight.isCheapestDirect,
        total_package_price: flight.total_package_price,
        datesInfo: flight.datesInfo,
      };
    }

    return null;
  },
);

export const getIsRegularFlight = createSelector(
  getSelectedFlightData,
  (flight) => {
    if (!flight) {
      return null;
    }

    return Boolean(flight.back[0].warning);
  },
);

/**
 * Проверяет, можно ли отложить ввод паспортных данных
 *
 * Если перелетов нет (ручной пакет) или это регулярный рейс,
 * ввод паспортных данных пользователя отложить нельзя
 */
export const getAllowDocsLater = createSelector(
  [getIsRegularFlight, getSelectedFlightData, isLTOperatorSelector],
  (isRegularFlight, flight, isLTOperator) => {
    if (!flight || isRegularFlight || isLTOperator) return false;

    if (!flight.to.departure) return false;

    const flightToDeparture = new Date(flight.to.departure);
    const today = new Date();

    const daysBeforeFlight = differenceInDays(flightToDeparture, today);

    if (daysBeforeFlight >= CHANGING_DAYS_LIMIT) {
      return true;
    }

    return false;
  },
);

export const getIsRegularCheapestFlight = createSelector(
  cheapestFlightSelector,
  (flight) => Boolean(flight) && Boolean(flight?.back[0].warning),
);

export const getFlightFuelCharge = createSelector(
  [getSelectedFlightData, minFuelChargeSelector],
  (flight, minimumCharge) => {
    const value = flight ? flight.back[0].fuel_charge : minimumCharge;
    return value;
  },
);

export const getFlightExtras = createSelector(
  getOrder,
  (orderState) => orderState.flight_extras,
);

// Order creation steps:
export const getIsFirstStepDone = createSelector(
  [getIsSkipFlights, getOrderFlight],
  (isSkipFlights, orderFlight) => !!isSkipFlights || !!orderFlight,
);

export const isSecondStepDone = createSelector(
  [getIsFirstStepDone, getOrderTouristsConfirmed],
  (isFirstStepDone, orderTouristsConfirmed) =>
    isFirstStepDone && orderTouristsConfirmed,
);

export const getOrderIsFinished = createSelector(
  getOrder,
  (orderState) => orderState.isFinished,
);

export const getCompletedStep = createSelector(
  [getOrderIsFinished, isSecondStepDone, getIsFirstStepDone],
  (isOrderFinished, isSecondStepFinished, isFirstStepFinished) => {
    // страница успешного создания заказа
    if (isOrderFinished) return 3;
    // страница оплаты и доп услуг
    if (isSecondStepFinished) return 2;
    // страница заполнения данных о туристах
    if (isFirstStepFinished) return 1;
    // главная страница чекаута
    return 0;
  },
);

export const getJointOrderSelector = createSelector(
  getOrder,
  (orderState) => orderState.jointOrder,
);

export const ordersAmountSelector = createSelector(
  getJointOrderSelector,
  (jointOrder) => jointOrder.ordersAmount,
);

export const firstOrderNumberSelector = createSelector(
  getJointOrderSelector,
  (jointOrder) => jointOrder.firstOrderNumber,
);

export const isFirstOrderSelector = createSelector(
  getJointOrderSelector,
  (jointOrder) => jointOrder.isFirstOrder,
);

// Order comments info:
export const getOrderComment = createSelector(
  getOrder,
  (orderState) => orderState.comment,
);

export const getOrderCommentSerialized = createSelector(
  [getOrderComment, isFirstOrderSelector, firstOrderNumberSelector],
  (comment, isFirstOrder, firstOrderNumber) => {
    const checkboxes = comment.checkboxes
      .filter(({ checked }) => Boolean(checked))
      .map(({ text, input }) => (input ? `${text}: ${input.value}` : text));
    const result = [...checkboxes, comment.text];
    if (!isFirstOrder && isFirstOrder !== null)
      result.unshift(`Совместно с другим заказом: ${firstOrderNumber}`);
    return result.join(';');
  },
);

// Flights info:
export const getOriginalFlightId = createSelector(
  getOrder,
  (orderState) => orderState.originalFlight,
);

export const getOrderFlightInfo = createSelector(
  getOrder,
  (orderState) => orderState.flightInfo,
);

export const getOrderFlightIsBusiness = createSelector(
  getOrderFlightInfo,
  (flightInfo) => flightInfo && flightInfo.business,
);

export const getOrderIsCreated = createSelector(
  getOrder,
  (orderState) => orderState.isCreated,
);

export const getOrderIsProcessed = createSelector(
  getOrder,
  (orderState) => orderState.isProcessed,
);

// Price info:
export const getPriceOnPay = createSelector(
  getOrder,
  (orderState) => orderState.priceOnPay,
);

export const getPackageFullPrice = createSelector(
  getOrder,
  (orderState) => orderState.fullPrice,
);

export const getIsPriceChangedOnPay = createSelector(
  [getPriceOnPay, getPackageFullPrice],
  (priceOnPay, currentPrice) =>
    !isNil(priceOnPay) && priceOnPay !== currentPrice,
);

export const _getPriceInfo = createSelector(
  getOrder,
  (orderState) => orderState.priceInfo,
);

export const getPriceInfo = createSelector(
  [_getPriceInfo, getPaymentData, getOrderIsCreated, getTransferInfo],
  (priceInfo, paymentData, isCreated) => {
    const array = priceInfo || [];

    if (!isCreated || !paymentData) {
      return array;
    }

    const { markup, type } = paymentData;
    const shouldAddServiceFee = markup > 0;
    const resultArray = filter(array, (item) => item.price_id !== type);

    if (shouldAddServiceFee) {
      resultArray.push({
        amount: markup,
        price_id: type ?? '',
        title: i18n.t('checkout.extraComment.paymentServiceFee'),
      });
    }

    return resultArray;
  },
);

const transformExtraType = (priceId: string): PriceDetailType['type'] => {
  switch (priceId) {
    case 'cashback':
      return 'cashback';
    case 'fuel_charge':
      return 'extra';
    default:
      return 'basic';
  }
};

export const getPriceDetails = createSelector(
  getPriceInfo,
  (priceInfoMap): PriceDetailType[] =>
    priceInfoMap
      ? priceInfoMap.map((priceInfo) => ({
          description: priceInfo.title,
          price: priceInfo.amount,
          type: transformExtraType(priceInfo.price_id),
        }))
      : [],
);

export const getRemainingPaymentAmount = createSelector(
  [getOrderIsCreated, getPaymentData, getPackageFullPrice, getErrorAction],
  (isCreated, paymentData, fullPrice, errorAction) =>
    isCreated && errorAction !== PAY_ORDER && paymentData?.amount
      ? paymentData.amount + paymentData.leftToPay
      : fullPrice,
);

// Order bonuses:
export const getOriginalPackageOrderBonuses = createSelector(
  getOrder,
  (orderState) => orderState.bonuses,
);

export const orderOriginalTotalBonuses = createSelector(
  getOriginalPackageOrderBonuses,
  (bonuses) => bonuses?.bonus_total || 0,
);

export const getOriginalPackageOrderFuelFreeBonuses = createSelector(
  getOriginalPackageOrderBonuses,
  (bonuses) => bonuses?.fuelfree_bonuses_relative || 0,
);

// Order discounts:
export const getDiscountMethods = createSelector(
  getOrder,
  (orderState) => orderState.discountMethods,
);

export const getOrderDiscountPrice = createSelector(
  getOrder,
  (orderState) => orderState.discountPrice,
);

export const getOrderDiscountType = createSelector(
  getOrder,
  (orderState) => orderState.discountType || undefined,
);

export const getOrderBonusesGranted = createSelector(
  [getOrderDiscountType, getOrderDiscountPrice],
  (discountType, usedBonus) =>
    discountType === DISCOUNT_BONUS ? usedBonus : 0,
);

export const getOrderCoupon = createSelector(
  getOrder,
  (orderState) => orderState.coupon?.pin || null,
);

// Order info:
export const getOrderId = createSelector(
  getOrder,
  (orderState) => orderState.id,
);

export const getOrderPin = createSelector(
  getOrder,
  (orderState) => orderState.pin || '',
);

// Prebuild:
export const getIsOrderPrebuildFetching = createSelector(
  getOrder,
  (orderState) => orderState.isPrebuildFetching,
);

export const getIsOrderPrebuilt = createSelector(
  getOrder,
  (orderState) => orderState.isPrebuilt,
);

export const getOrderErrors = createSelector(
  getOrder,
  (orderState) => orderState.errors,
);

// Checks:
export const makeGetOrderFieldError = (field) =>
  createSelector(getOrderErrors, (errors) => errors && errors[field]);

export const getInsurancePrice = createSelector(
  getOrder,
  (orderState) => orderState.insurancePrice,
);

// Extras:
export const getOrderExtras = createSelector(
  getOrder,
  (orderState) => orderState.orderExtras,
);

export const getCanCreateOrder = createSelector(
  [getPaymentMethod, getCardIsValid, getIsPrebookInProgress],
  (paymentMethod, isValidCard, isPrebookIsProgress) => {
    const isCard = getPaymentGroup(paymentMethod) === PAYMENT_GROUPS.CARD;
    return (
      ((isValidCard || !isCard) && !isPrebookIsProgress) ||
      paymentMethod === 't_bank_credit' ||
      paymentMethod === 'sbp' ||
      paymentMethod === 't_pay'
    );
  },
);

export const getCheapestPriceWithFlight = createSelector(
  [getNetPrice, cheapestFlightSelector, getFlightExtras],
  (netPrice, cheapestFlight, extras): number =>
    netPrice +
      (cheapestFlight?.to.fuel_charge || 0) +
      (extras?.reduce((sum, extra) => extra && sum + (extra.price || 0), 0) ||
        0) || 0,
);

export const selectCharterFlight = createSelector(
  getSelectedFlightData,
  (flight) => {
    if (!flight) {
      return null;
    }

    return flight.back[0].type === 'charter';
  },
);
