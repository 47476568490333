export const SERVER_DATE_FORMAT = 'yyyy-MM-dd';
export const CLIENT_DATE_FORMAT = 'dd.MM.yyyy';

export const MODULE_NAME = {
  PACKAGE_CHECKOUT: 'package_checkout',
  HOTEL_CHECKOUT: 'hotel_checkout',
  HOTEL: 'hotel',
  HOTEL_REVIEWS_PAGE: 'hotel_reviews_page',
  GIFT_CERT: 'gift_certificate',
  SEARCH: 'search',
  CONFIRM_EMAIL: 'confirm_email',
  SEO: 'seo',
  CONTACTS: 'contacts',
  CANCELLATION_POLICY: 'cancellation_policy',
  WARN_DEBT: 'warn_debt',
  ORDER_CHANGING_REQUEST: 'order_changing_request',
  MAIN_ORDER: 'main_order',
  PAY_CARD: 'pay_card',
  HOME: 'home',
  ORDER: 'order',
  MODERN_MOBILE_HOME: 'modern_mobile_home',
  MODERN_DESKTOP_HOME: 'modern_desktop_home',
  WISHLIST: 'wishlist',
  IFRAME_HOME: 'iframe_home',
  WEBVIEW_HOME: 'webview_home',
  SBP: 'sbp',
  TBANK_CREDIT: 't_bank_credit',
  CLIENT: 'client', // ЛК
  T_PAY: 't_pay',
} as const;

export const HOME_PATH = '';
export const SEARCH_PATH = '/search';
export const HOTEL_PATH = '/hotels';
export const PACKAGES_PATH = '/packages';
export const HOTEL_REVIEWS_PAGE = '/hotel_reviews';

export const GENDER = {
  MALE: 'M',
  FEMALE: 'F',
} as const;

export const VISA_SERVICE_TYPE = {
  NO_VISA: 'no_visa',
  VISA_FULL: 'visa_full',
  VISA_DOCS: 'visa_docs',
};

export const NATIONALITY_CONSTANTS = {
  RU: 225,
  BY: 149,
};

//
export const MONTHS_SHORT = [
  'янв',
  'фев',
  'мар',
  'апр',
  'мая',
  'июн',
  'июл',
  'авг',
  'сен',
  'окт',
  'ноя',
  'дек',
];

export const PATTERN = {
  NO_LATIN: '^[^a-zA-Z0-9]+$',
  NO_CYRILLIC: '^([^а-яё]+)$',
};

export const BREAKEPOINTS = {
  small: 694,
  medium: 960,
  bigger: 1110,
  large: 1270,
  extraLarge: 1600,
};

export const DEFAULT_WINDOWS_TIMEOUT = 300;

// Выбор перелета
export const DEFAULT_TOPBAR_SLIDE = 1;

export const MOSCOW_CITY_ID = 213;
export const DEFAULT_DEPARTURE_COUNTRY_ISO2 = 'RU';

export const STATUS_INTERVAL = 2 * 1000; // 2s
export const SEARCH_TIMEOUT = 61 * 1000; // 61s
export const ENRICHED_DELAY = 3000; // 3s

export const enToRusKeyboardMap = new Map([
  ['q', 'й'],
  ['w', 'ц'],
  ['e', 'у'],
  ['r', 'к'],
  ['t', 'е'],
  ['y', 'н'],
  ['u', 'г'],
  ['i', 'ш'],
  ['o', 'щ'],
  ['p', 'з'],
  ['[', 'х'],
  ['{', 'х'],
  [']', 'ъ'],
  ['}', 'ъ'],
  ['a', 'ф'],
  ['s', 'ы'],
  ['d', 'в'],
  ['f', 'а'],
  ['g', 'п'],
  ['h', 'р'],
  ['j', 'о'],
  ['k', 'л'],
  ['l', 'д'],
  [';', 'ж'],
  ["'", 'э'],
  ['"', 'э'],
  ['\\', 'ё'],
  ['|', 'ё'],
  ['z', 'я'],
  ['x', 'ч'],
  ['c', 'с'],
  ['v', 'м'],
  ['b', 'и'],
  ['n', 'т'],
  ['m', 'ь'],
  [',', 'б'],
  ['<', 'б'],
  ['.', 'ю'],
  ['>', 'ю'],
]);
