import { connect } from 'react-redux';
import { getIsWhitelabel } from 'src/store/view/selectors';
import { getIsLoggedIn } from 'src/store/client/selectors';
import DesktopMenu from './DesktopMenu';

const mapStateToProps = (state) => ({
  isWhiteLabel: getIsWhitelabel(state),
  isLoggedIn: getIsLoggedIn(state),
});

export default connect(mapStateToProps)(DesktopMenu);
