import { Tour, Hotel, Pin } from '@lt/components/icons-react/rebrand';

export const SEARCH_TYPE_NAMES = {
  PACKAGE: 'package',
  HOTEL: 'hotel',
  TRIP: 'trip',
} as const;

export const SEARCH_TYPES = [
  {
    name: SEARCH_TYPE_NAMES.PACKAGE,
    label: 'Туры',
    Icon: Tour,
  },
  {
    name: SEARCH_TYPE_NAMES.HOTEL,
    label: 'Отели',
    altLabel: 'Только проживание',
    Icon: Hotel,
  },
  {
    name: SEARCH_TYPE_NAMES.TRIP,
    label: 'Экскурсии',
    Icon: Pin,
  },
];
