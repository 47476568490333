import type { SagaReturnType } from 'redux-saga/effects';
import { all, put, select, takeLatest } from 'redux-saga/effects';

import { DTOError } from 'src/error/errors/DTOError';
import { logErrorToSentry } from 'src/error/utils/logErrorToSentry';
import { ERROR_TYPE } from 'src/store/error/constants';
import { sendPayAnalytics } from 'src/store/paymentAnalytics/sagas';

import { RequestError } from '@lt/api';
import type { OverloadingReturnType } from 'src/types/utility';
import { PAY_ORDER, PAYMENT_ERRORS } from './constants';

import { api } from '../../api';

import {
  getCardData,
  selectOrderID,
  selectOrderPin,
  getPaymentAmount,
  getPaymentMethod,
  selectPaymentId,
  selectCurrentTariffID,
} from './selectors';

import { setError } from '../error/slice';
import type { NewErrorPayload } from '../error/types';
import { resetPopupState, setPopupState } from '../popup/slice';
import { searchTypeSelector } from '../view/selectors';
import {
  initPayment,
  paymentFailed,
  payUniteller,
  confirmPayment,
  submitCres,
  submitPares,
} from './actions';
import {
  finishPayment,
  openPay2meFrame,
  payCardSuccessed,
  set3dsDataV1,
  set3dsDataV2,
  setPaylateData,
} from './slice';

function* createPayment() {
  try {
    yield* sendPayAnalytics('payment_started');
    const paymentMethod: SagaReturnType<typeof getPaymentMethod> = yield select(
      getPaymentMethod,
    );
    if (!paymentMethod) {
      throw new Error(
        `initPayment ОПЛАТА НЕ ПРОЙДЕТ, потому что paymentMethod ${paymentMethod}`,
      );
    }

    const orderId: SagaReturnType<typeof selectOrderID> = yield select(
      selectOrderID,
    );
    if (!orderId) throw new Error(`Заказ еще не создан, orderId: ${orderId}`);

    switch (paymentMethod) {
      case 'sbp':
        yield* processSbpPayment(orderId);
        break;
      case 't_pay':
        yield* processTPayPayment(orderId);
        break;
      case 'paylate':
        yield* processPaylate(orderId, 'paylate');
        break;
      case 'paylate_120':
        yield* processPaylate(orderId, 'paylate_120');
        break;
      case 't_bank_credit':
        yield* processTbankCredit(orderId);
        break;
      case 'pay_later':
      case 'cash':
        yield put(finishPayment());
        break;
      default:
        yield* processCardPayment();
        break;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error, PAYMENT_ERRORS.PAYMENT_CREATION);
    yield* handlePaymentFailed({
      type: ERROR_TYPE.ORDER,
      action: PAY_ORDER,
      message: PAYMENT_ERRORS.PAYMENT_CREATION,
      error: error instanceof Error ? error : undefined,
    });
  }
}

function* processTbankCredit(orderID: number) {
  try {
    const pin: SagaReturnType<typeof selectOrderPin> = yield select(
      selectOrderPin,
    );
    const tariffID: SagaReturnType<typeof selectCurrentTariffID> = yield select(
      selectCurrentTariffID,
    );

    window.location.href = `${window.location.origin}/payment/t_bank_credit?tariffID=${tariffID}&orderID=${orderID}&pin=${pin}`;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error, PAYMENT_ERRORS.TBANK_CREDIT);
    yield* handlePaymentFailed({
      type: ERROR_TYPE.ORDER,
      action: PAY_ORDER,
      message:
        error instanceof RequestError
          ? error.message
          : PAYMENT_ERRORS.TBANK_CREDIT,
      error: error instanceof DTOError ? error : undefined,
    });
  }
}

function* processTPayPayment(orderId: number) {
  const pin: SagaReturnType<typeof selectOrderPin> = yield select(
    selectOrderPin,
  );
  const searchType: SagaReturnType<typeof searchTypeSelector> = yield select(
    searchTypeSelector,
  );
  window.location.href = `${window.location.origin}/payment/t_pay?&order_id=${orderId}&pin=${pin}&search_type=${searchType}`;
}

function* processSbpPayment(orderId: number) {
  try {
    const params = {
      payment_method: 'sbp',
      order_id: orderId,
    } as const;
    const response: OverloadingReturnType<
      typeof api.postPaymentsPaymentParams,
      typeof params
    > = yield api.postPaymentsPaymentParams(params);
    const { paymentId } = response;

    const pin: SagaReturnType<typeof selectOrderPin> = yield select(
      selectOrderPin,
    );
    const searchType: SagaReturnType<typeof searchTypeSelector> = yield select(
      searchTypeSelector,
    );
    window.location.href = `${window.location.origin}/payment/sbp?payment_id=${paymentId}&order_id=${orderId}&pin=${pin}&search_type=${searchType}`;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error, PAYMENT_ERRORS.SBP);
    yield* handlePaymentFailed({
      type: ERROR_TYPE.ORDER,
      action: PAY_ORDER,
      message:
        error instanceof RequestError ? error.message : PAYMENT_ERRORS.SBP,
      error: error instanceof DTOError ? error : undefined,
    });
  }
}

function* processPaylate(
  orderId: number,
  paylateTariff: 'paylate' | 'paylate_120',
) {
  try {
    const params = {
      payment_method: paylateTariff,
      order_id: orderId,
    } as const;

    const response: OverloadingReturnType<
      typeof api.postPaymentsPaymentParams,
      typeof params
    > = yield api.postPaymentsPaymentParams(params);

    yield put(setPaylateData(response));
    yield put(resetPopupState());
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error, PAYMENT_ERRORS.PAYLATE);
    yield* handlePaymentFailed({
      type: ERROR_TYPE.ORDER,
      action: PAY_ORDER,
      message:
        error instanceof RequestError ? error.message : PAYMENT_ERRORS.PAYLATE,
      error: error instanceof DTOError ? error : undefined,
    });
  }
}

/**
 * Обработка платежа uniteller
 */
function* processCardPayment() {
  try {
    const orderId: SagaReturnType<typeof selectOrderID> = yield select(
      selectOrderID,
    );
    if (!orderId) throw new Error('Order is not yet created');

    const cardData: SagaReturnType<typeof getCardData> = yield select(
      getCardData,
    );
    const amount: SagaReturnType<typeof getPaymentAmount> = yield select(
      getPaymentAmount,
    );
    const paymentMethod: SagaReturnType<typeof getPaymentMethod> = yield select(
      getPaymentMethod,
    );

    if (!cardData) {
      throw new Error(`Не пришли данные cardData: ${cardData}`);
    }
    const { number: pan, expMonth, expYear, cvv } = cardData;

    if (!amount) {
      throw new Error(
        `handleUnitellerPayment ОПЛАТА НЕ ПРОЙДЕТ, потому что amount: ${amount}`,
      );
    }

    const response: SagaReturnType<typeof api.postPaymentsPayCard> =
      yield api.postPaymentsPayCard({
        pan,
        cvv,
        exp_month: expMonth,
        exp_year: `20${expYear}`,
        amount,
        payment_method: paymentMethod,
        order_id: orderId,
        browser_info: {
          timezoneOffset: new Date().getTimezoneOffset(),
          colorDepth: window.screen.colorDepth,
          screenHeight: window.screen.height,
          screenWidth: window.screen.width,
          language: window.navigator.language,
          userAgent: navigator.userAgent,
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
        },
      });

    const { paymentId, url } = response;

    yield put(payCardSuccessed(paymentId));

    switch (response.processorIdentifier) {
      case 'uniteller_v1':
        yield put(
          set3dsDataV1({
            pareq: response.pareq,
            md: response.md,
            termUrl: response.termUrl,
            url,
          }),
        );
        yield put(setPopupState('3ds'));
        return;
      case 'uniteller_v2':
        yield put(
          set3dsDataV2({
            creq: response.creq,
            url,
            ...('data' in response
              ? { data: response.data }
              : { iframeInlineHTML: response.iframeInlineHTML }),
          }),
        );
        yield put(setPopupState('3ds2.0'));
        return;
      case 'pay2me_v1':
      case 'pay2me_v2':
        yield put(openPay2meFrame(response.url));
        yield put(setPopupState('pay2me'));
        return;
      default:
    }
  } catch (error) {
    yield* handlePaymentFailed({
      type: ERROR_TYPE.ORDER,
      action: PAY_ORDER,
      message:
        error instanceof RequestError ? error.message : PAYMENT_ERRORS.PAY_CARD,
      error: error instanceof DTOError ? error : undefined,
    });
  }
}

/**
 * Получаем pares или cres от эквайринга и отдаем на сервер для заморозки платежа
 */
function* handle3dsSubmit(
  action: ReturnType<typeof submitCres> | ReturnType<typeof submitPares>,
) {
  try {
    const { type, payload } = action;

    const paymentId: SagaReturnType<typeof selectPaymentId> = yield select(
      selectPaymentId,
    );

    if (!paymentId) {
      throw new Error(
        `handle3dsPayment ОПЛАТА НЕ ПРОЙДЕТ, потому что payment_id: ${paymentId}`,
      );
    }

    yield api.postPaymentsPay3ds({
      payment_id: paymentId,
      ...(type === 'payment/submitCres'
        ? { cres: payload }
        : { pares: payload }),
    });

    yield put(finishPayment());
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e, PAYMENT_ERRORS.THREE_DS);
    yield* handlePaymentFailed({
      type: ERROR_TYPE.ORDER_3DS,
      action: PAY_ORDER,
      message: e instanceof RequestError ? e.message : PAYMENT_ERRORS.THREE_DS,
      error: e instanceof DTOError ? e : undefined,
    });
  }
}

/**
 *  Запрос на подтверждение платежа к банку
 */
function* confirmPayment3ds() {
  const paymentId: SagaReturnType<typeof selectPaymentId> = yield select(
    selectPaymentId,
  );

  try {
    if (!paymentId) {
      throw new Error(
        `handleSubmit3dsData ОПЛАТА НЕ ПРОЙДЕТ, потому что payment_id: ${paymentId}`,
      );
    }

    const response: SagaReturnType<typeof api.postPaymentsData3ds> =
      yield api.postPaymentsData3ds({
        payment_id: paymentId,
      });
    const { status, data3ds } = response;
    // Если статус `paid` – значит оплата прошла успешно
    if (status === 'paid') {
      yield put(finishPayment());
      return;
    }

    if (!data3ds) throw new DTOError('No data3ds provided in 3ds2.0', data3ds);

    yield put(set3dsDataV2(data3ds));
    yield put(setPopupState('3ds'));
  } catch (error) {
    yield* handlePaymentFailed({
      message:
        error instanceof RequestError
          ? error.message
          : PAYMENT_ERRORS.PAY_ERROR,
      error: error instanceof DTOError ? error : undefined,
    });
  }
}

export function* handlePaymentFailed(
  payload: NewErrorPayload & { error?: Error },
) {
  yield put(setError(payload));
  yield put(setPopupState('error'));

  const { error } = payload;
  if (error) {
    logErrorToSentry(error, undefined);
    yield* sendPayAnalytics('payment_failed', error.message);
  } else {
    logErrorToSentry(new Error(payload.message), undefined);
    yield* sendPayAnalytics('payment_failed', payload.message);
  }
}

function* callPaymentSaga(action: ReturnType<typeof paymentFailed>) {
  yield* handlePaymentFailed(action.payload);
}

export default function* paymentSaga() {
  yield all([
    // Step 1: INIT
    takeLatest(initPayment, createPayment),
    // Step 2: Uniteller
    takeLatest(payUniteller, processCardPayment),
    // Step 3: 3ds2.0
    takeLatest(confirmPayment, confirmPayment3ds),
    // Step 4: 3ds (optional)
    takeLatest(submitCres, handle3dsSubmit),
    takeLatest(submitPares, handle3dsSubmit),

    takeLatest(paymentFailed, callPaymentSaga),
  ]);
}
