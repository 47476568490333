import { formatDate } from '@lt/components/utils/date/index';
import {
  parse,
  intervalToDuration,
  addDays,
  subDays,
  differenceInCalendarDays,
  differenceInYears,
  getMinutes,
  getSeconds,
  parseISO,
  isBefore,
  isAfter,
  isSameMonth,
} from 'date-fns';

import { pluralizeHoursGen, pluralizeMinutesGen } from './pluralizeUtils';

import { CLIENT_DATE_FORMAT, SERVER_DATE_FORMAT } from '../constants';
import { NBSP } from '../constants/common';

/**
 * Форматирует дату, например 23 сен, пт
 * @param {string} date
 * @returns {string}
 */
export const formatDateToDay = (date) => formatDate(date, 'd MMM, EEEEEE');

/**
 * Форматирует дату, например 23 сен
 * @param {string} date
 * @returns {string}
 */
export const formatDateToDayShort = (date) => formatDate(date, 'd MMM');

/**
 * Форматирует дату, например 23 сен 2019
 * @param {string | Date} date
 * @returns {string}
 */
export const formatDateToDayShortWithYear = (date: string | Date): string =>
  formatDate(date, 'd MMM yyyy');

/**
 * Форматирует дату, например 23 сентября
 * @param {string} date
 * @returns {string}
 */
export const formatDateToDayWithMonth = (date) => formatDate(date, 'dd MMMM');

/**
 * Форматирует дату полностью, например 23 сентября 2018
 * @param {string} date
 * @returns {string}
 */
export const formatDateToDayFull = (date) => formatDate(date, 'd MMMM yyyy');

/**
 * Возвращает время из даты
 * @param {Date} date
 * @returns {string} hh:mm
 */
export const getHours = (date) => formatDate(date, 'HH:mm');

/**
 *
 * @param {string} string Date format
 * @deprecated
 */
export const checkDateFormat = (string) => {
  const regex = /^([0-2][0-9]|(3)[0-1])(\.)(((0)[0-9])|((1)[0-2]))(\.)\d{4}$/;
  return regex.test(string);
};

/**
 * Конвертирует серверную дату YYYY-MM-DD в клиентскую DD.MM.YYYY
 * @param {string | Date} string YYYY-MM-DD
 * @returns {string} DD.MM.YYYY
 */
export const convertServerDateToClientDate = (string) =>
  formatDate(string, CLIENT_DATE_FORMAT);

/**
 * Конвертирует клиентскую дату в серверную YYYY-MM-DD
 * @param {string} string DD.MM.YYYY
 * @returns {string} YYYY-MM-DD
 */
export const convertClientDateToServerDate = (date: string) => {
  const prepareDate = parse(date, CLIENT_DATE_FORMAT, new Date());
  return formatDate(prepareDate, SERVER_DATE_FORMAT);
};

/**
 * Возвращает дату в формате 2019-03-16
 * @param {Date} date
 * @returns {string}
 */
export const formatServerDate = (date) => formatDate(date, SERVER_DATE_FORMAT);

/**
 * Возвращает день недели
 * @param {string} date
 * @returns {string} пятница
 */
export const getDateName = (date, formatToken = 'eeee') =>
  formatDate(date, formatToken);

/**
 * Возвращает время в формате 1 д 2 ч 25 мин
 * @param {number} value seconds
 * @param {string} minuteSuffix суфикс
 * @returns {string}
 */
export const getFormattedDuration = (value, minuteSuffix = 'мин') => {
  const prepareDuration = intervalToDuration({ start: 0, end: value * 1000 });

  const { days, hours, minutes } = prepareDuration;

  const formattedDays = days ? `${days} д ` : '';
  const formattedHours = hours ? `${hours} ч ` : '';
  const formattedMinutes = minutes ? `${minutes} ${minuteSuffix}` : '';

  return [formattedDays, formattedHours, formattedMinutes].join('');
};

/**
 * Возвращает смещение в днях между датами
 * @param {string} previousDate 2020-02-12
 * @param {string} nextDate 2020-02-15
 * @returns {string} 3
 */
export const getDaysDiff = (previousDate, nextDate) =>
  differenceInCalendarDays(new Date(nextDate), new Date(previousDate));

/**
 * Возвращает оставшееся время в виде строки минуты:секунды (0:24)
 * @param {number} diff milliseconds
 * @returns {string}
 */
export const getRemainingTime = (diff) => {
  const date = new Date(diff);

  const minutes = getMinutes(date);
  const seconds = getSeconds(date);

  const formatedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${minutes}:${formatedSeconds}`;
};

/**
 * Возвращает время в секундах до переданной даты
 * по московскому времени
 * @param endDate
 */
export const getRemainingTimeMoscow = (endDate: string): number =>
  Math.floor(
    (new Date(endDate).getTime() -
      Date.parse(
        new Date().toLocaleString('en-US', {
          timeZone: 'Europe/Moscow',
        }),
      )) /
      1000,
  );

/**
 * Возвращает количество полных лет по дате рождения
 * @param {String} birthday 2000-02-12
 * @returns {Number} 21
 * // TODO: перенести в утилзы туристов
 */
export const getAge = (birthday) => {
  if (!birthday) {
    return null;
  }

  const current = new Date();
  const touristBirthday = new Date(birthday);
  if (
    current.getMonth() === touristBirthday.getMonth() &&
    current.getDate() === touristBirthday.getDate()
  )
    return differenceInYears(current, touristBirthday) - 1;
  return differenceInYears(current, touristBirthday);
};

/**
 * Возвращает дату завершения тура
 * @param {String} startDate дата начала тура
 * @param {String} nightsCount количество ночей
 * @returns {string} date YYYY-MM-DD
 */
export const getPackageEndDate = (startDate, nightsCount) => {
  const date = new Date(startDate);
  const endDate = addDays(date, nightsCount);
  const formattedEndDate = formatServerDate(endDate);

  return formattedEndDate;
};

/**
 * Возвращает время в зависимости от расстояния и времени
 * @param {number} distance metres
 * @param {number} speed km/h
 * @returns {string}
 */
export const timeBySpeedGen = (distance, speed = 60) => {
  const minutes = (distance / (1000 * speed)) * 60;
  const hours = parseInt((minutes / 60).toString(), 10);
  let minutesWithoutHours = parseInt((minutes % 60).toString(), 10);

  if (!minutesWithoutHours && !hours) {
    minutesWithoutHours = 1;
  }

  const hoursText =
    hours > 0 ? `${hours}${NBSP}${pluralizeHoursGen(hours)}` : '';
  const minutesText =
    minutesWithoutHours > 0
      ? `${minutesWithoutHours}${NBSP}${pluralizeMinutesGen(
          minutesWithoutHours,
        )}`
      : '';

  return hoursText ? `${hoursText} ${minutesText}` : minutesText;
};

/**
 * Возвращает флекс даты
 * @param {Date} startDate
 * @returns {object} { from: Date, to: Date }
 */
export const getFlexDates = (startDate) => ({
  from: subDays(startDate, 2),
  to: addDays(startDate, 2),
});

/**
 * Возвращает день из даты
 * @param {string} startDate 2020-02-12
 * @returns 12
 */
export const getDay = (startDate) => formatDate(startDate, 'd');
/**
 * Возвращает месяц из даты
 * @param {string} startDate 2020-02-12
 * @returns фев
 */
export const getShortMonth = (startDate) => formatDate(startDate, 'MMM');

export const parseISODate = (ISODate: string): Date => parseISO(ISODate);

export const isDateBefore = (date: Date, dateToCompare: Date): boolean =>
  isBefore(date, dateToCompare);

export const isDateAfter = (date: Date, dateToCompare: Date): boolean =>
  isAfter(date, dateToCompare);

export const getMidnightFromString = (date: string | Date): number =>
  new Date(date).setHours(0, 0, 0, 0);

interface UpdateDateProps {
  date: Date | string;
  direction: 'next' | 'prev';
  daysCount: number;
}
/**
 * Меняет дату в прошлом или будущем на daysCount
 * @param date Date | SERVER_DATE_FORMAT
 * @return SERVER_DATE_FORMAT
 *
 * @see SERVER_DATE_FORMAT
 */
export const updateDate = ({
  date,
  direction,
  daysCount,
}: UpdateDateProps): string => {
  const directionFunc = direction === 'next' ? addDays : subDays;

  const newDate = directionFunc(new Date(date), daysCount);
  return formatDate(newDate, SERVER_DATE_FORMAT);
};

/** Возвращает рейнж дат, если есть флексы
 * @returns если флексов нет, возвращаем 23 сен
 * @returns если рейнж дат попадает в один месяц, возвращаем 24..28 ноя
 * @returns если месяцы разные, возвращаем 24 ноя..03 дек
 */
export const getDateRangeString = (date: Date, flexDates: number) => {
  if (!flexDates) return formatDateToDayShort(date);

  const minDate = subDays(date, flexDates);
  const maxDate = addDays(date, flexDates);
  const isEqualMonth = isSameMonth(minDate, maxDate);
  const max = formatDateToDayShort(maxDate);
  let min: string;

  if (isEqualMonth) {
    min = formatDate(minDate, 'd');
  } else {
    min = formatDateToDayShort(minDate);
  }

  return `${min}..${max}`;
};
