import {
  getFromImmutableStateCompatible,
  getImmutableCompatible,
} from 'src/utils/immutableUtils';
import { createSelector } from '@reduxjs/toolkit';
import { some } from 'lodash';
import { getRouletteHotelTitle } from 'src/utils/stringUtils';
import { i18n } from '@lt/localization';
import type { HotelStateType } from './types';
import { HOTEL_FACTS_VALUES, HOTEL_FACTS_TYPES } from './constants';
import { transformFacilities, transformFacilitiesWithIcons } from './utils';

const _getParams = (state) => getImmutableCompatible(state, 'hotel');
export const getHotelData =
  getFromImmutableStateCompatible<HotelStateType>(_getParams);

/**
 * Возвращает id отеля
 */
export const getHotelId = (state): number | string => getHotelData(state).id;

/**
 * Возвращает имя отеля
 */
export const getHotelName = (state) => getHotelData(state).name;

/**
 * Возвращает ссылку на отель
 */
export const getHotelLink = (state) => getHotelData(state).public_url;

/**
 * Возвращает количество звезд отеля
 */
export const getHotelStars = (state) => getHotelData(state).stars;

/**
 * Возвращает id местоположения отеля
 */
export const getHotelPlaceId = (state) => getHotelData(state).place_id;

/**
 * Возвращает местоположение отеля
 */
const getHotelPlace = (state) => getHotelData(state)?.place?.name_ru;

/**
 * Возвращает название страны, в котором находится отель
 */
const getHotelCountryName = (state) => getHotelData(state)?.country?.name_ru;

/**
 * Возвращает цену отеля
 */
export const getInitialPrice = (state) => getHotelData(state).price;

/**
 * Возвращает расположение отеля
 */
export const getHotelLocation = createSelector(
  [getHotelCountryName, getHotelPlace],
  (country, place) => `${place}, ${country}`,
);

/**
 * Возвращает информацию об отеле
 */
export const getHotelInfo = (state) => getHotelData(state).hotel_info;

/**
 * Возвращает короткую информацию об отеле
 */
export const getHotelShortInfo = (state) => getHotelData(state).short_info;

/**
 * Возвращает широту координатов отеля
 */
const getHotelLat = (state): unknown => getHotelData(state).lat;

/**
 * Возвращает долготу координатов отеля
 */
const getHotelLong = (state): unknown => getHotelData(state).long;

/**
 * Возвращает координаты отеля
 */
export const getHotelCoordinates = createSelector(
  [getHotelLat, getHotelLong],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (lat, long): [any, any] | null => {
    if (!Number(lat) && !Number(long)) {
      return null;
    }

    return [long, lat];
  },
);

/**
 * Возвращает главную картинку
 */
const getHotelMainImage = (state) => getHotelData(state).main_image;

/**
 * Возвращает ссылку на главную картинку
 */
export const getHotelMainImageHref = createSelector(
  getHotelMainImage,
  (image) => image?.file?.x100x115?.url,
);

/**
 * Возвращает факты об отеле
 */
const _getFacts = (
  state,
): Record<string, string | number | boolean | object | undefined> =>
  getHotelData(state).facts;

/**
 * Возвращает факты об отеле
 * @param {object} state reducer
 * @returns факты об отеле
 */
export const getHotelFacts = createSelector(_getFacts, (facts) => {
  if (!facts) {
    return {};
  }

  const factsObj = { ...facts };

  const { line, ski_in, ski_out } = factsObj;

  if (line) {
    factsObj.beach_line = {
      line,
      distance: factsObj.beach_distance,
    };
    factsObj.line = undefined;
  }

  let skiInSkiOut = 'NONE';
  if (ski_in && ski_out) {
    skiInSkiOut = HOTEL_FACTS_VALUES.SKI_IN_SKI_OUT;
  } else if (ski_in) {
    skiInSkiOut = HOTEL_FACTS_VALUES.SKI_IN;
  } else if (ski_out) {
    skiInSkiOut = HOTEL_FACTS_VALUES.SKI_OUT;
  }
  factsObj[HOTEL_FACTS_TYPES.SKI_IN_SKI_OUT] = skiInSkiOut;

  return factsObj;
});

/**
 * Возвращает есть ли факты об отеле
 */
export const hasFacts = createSelector(getHotelFacts, (facts) =>
  some(facts, (val) => val && val !== 'NONE'),
);

/**
 * Возвращает удобства отеля
 */
const getFacilities = (facilities, setRandomBest?) =>
  transformFacilities(facilities, setRandomBest);

/**
 * Возвращает удобства отеля с иконками
 */
const getFacilitiesWithIcons = (facilities, setRandomBest) =>
  transformFacilitiesWithIcons(facilities, setRandomBest);

// TODO: убрать обертку getFacilities, когда будет норм бэк
/**
 * Возвращает удобства отеля связанные с территорией
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getHotelFacilitiesTerritory = (state): any =>
  getFacilities(getHotelData(state).territory);

/**
 * Возвращает удобства отеля связанные с детями
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getHotelFacilitiesKids = (state): any =>
  getFacilities(getHotelData(state).kids_facilities);

/**
 * Возвращает удобства отеля связанные с аттракционами
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getHotelFacilitiesAttractions = (state): any =>
  getFacilities(getHotelData(state).attractions);

/**
 * Возвращает удобства отеля связанные с номерами
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getHotelFacilitiesRoom = (state): any =>
  getFacilitiesWithIcons(getHotelData(state).room_traits, false);

// не бесполезен ли room_facilities в hotel,
// в котором то же самое, что в room_traits, но нет имен иконок?
// TODO: унифицировать объект данных и убрать маппер
export const getHotelFacilitiesRoomForSearcher = createSelector(
  getHotelFacilitiesRoom,
  (items) =>
    items &&
    items.map((item) => ({
      ...item,
      name: item.text,
    })),
);

/**
 * Возвращает рулетку от отеля Fortuna*
 */
export const getHotelRoulette = (state) => getHotelData(state).roulette;

/**
 * Возвращает заголовок в шапке отеля
 */
export const getHotelTitle = createSelector(
  [getHotelName, getHotelStars, getHotelRoulette],
  (name, stars, isRoutlette) =>
    isRoutlette ? getRouletteHotelTitle(name, stars) : name,
);

/**
 * Возвращает лейблы отеля с фолбэком на пустой массив
 */
export const getHotelLabels = (state) => getHotelData(state).labels || [];

/**
 * Возвращает прилинкованные отели
 */
export const getLinkedHotels = (state) => getHotelData(state).linked_hotels;

/**
 * Возвращает метаполитики отеля
 */
export const getHotelMetapolicies = (state) => getHotelData(state).metapolicies;

/**
 * Возвращает рейтинг отеля
 */
export const getHotelRating = (state) => getHotelData(state).rating;

/**
 * Получаем информацию о рецензии эксперта на отель
 */
const getHotelExpertReview = (state) => getHotelData(state).expert_review;

/**
 * Существует ли рецензия на отель
 */
export const getHotelIsExpertReviewExists = (state): boolean =>
  Boolean(getHotelExpertReview(state));

/**
 * Получаем заголовок для ревью
 */
export const getHotelExpertReviewTitle = (state) => {
  const hotelName = getHotelName(state);
  return i18n.t('expertReview.title', {
    hotelName,
    ns: 'hotel',
  });
};

/**
 * Получаем саму рецензию
 */
export const getHotelExpertReviewDescription = (state) =>
  getHotelExpertReview(state)?.text;

/**
 * Получаем информацию о эксперте написавшем рецензию
 */
const getHotelExpertReviewExpert = (state) =>
  getHotelExpertReview(state)?.expert;

/*
 * Получаем информацию об имени эксперта написавшего ревью
 */
export const getHotelExpertReviewExpertName = (state) =>
  getHotelExpertReviewExpert(state)?.name;

/**
 * Получаем информацию о должности эксперта
 */
export const getHotelExpertReviewExpertRegalia = (state) =>
  getHotelExpertReviewExpert(state)?.regailia;

/**
 * Получаем ссылку на аватар эксперта
 */
export const getHotelExpertReviewExpertAvatar = (state) =>
  getHotelExpertReviewExpert(state)?.avatar;

/**
 * Получаем список ответов и вопросов
 */
export const getHotelFAQ = (state) => getHotelData(state).faq;

/**
 * Получаем флаг для показа FAQ
 */
export const getIsShowHotelFAQ = (state): boolean => {
  const hotelFAQ = getHotelFAQ(state);

  if (!Array.isArray(hotelFAQ)) return false;

  return Boolean(hotelFAQ.length);
};
