import { createSelector } from '@reduxjs/toolkit';
import type { FlightsData } from 'src/routes/Checkout/CheckoutRoutes/PackageCheckout/types/flights';
import { getEndDate, getStartDate } from '../../../../store/selectors/package';
import { getFlightClass } from '../../../../store/selectors/view';
import { FLIGHT_CLASSES, FLIGHT_TYPES_NAME } from './constants';
import type { FlightsSlice } from './slice';
import { name } from './slice';
import type { SkeletonBothFlightsInfo } from './types';

const flightSliceSelector = (state): FlightsSlice => state[name];

export const economyFlightsSelector = (state) =>
  flightSliceSelector(state).economFlights;

export const businessFlightsSelector = (state) =>
  flightSliceSelector(state).businessFlights;

export const checkoutBannersSelector = (state) =>
  flightSliceSelector(state).banners;

export const allFlightsSelector = (state) => [
  ...economyFlightsSelector(state),
  ...businessFlightsSelector(state),
];

export const loadingStateSelector = createSelector(
  flightSliceSelector,
  (flights) => flights.loadingState,
);

export const getSkeletonInfoSelector = createSelector(
  [getStartDate, getEndDate],
  (startDate, endDate): SkeletonBothFlightsInfo => ({
    to: {
      departure: startDate,
      arrival: startDate,
      departureCity: '—',
      arrivalCity: '—',
      flightType: FLIGHT_TYPES_NAME.NA,
    },
    back: {
      departure: endDate,
      arrival: endDate,
      departureCity: '—',
      arrivalCity: '—',
      flightType: FLIGHT_TYPES_NAME.NA,
    },
  }),
);

export const flightsListSelector = createSelector(
  [getFlightClass, economyFlightsSelector, businessFlightsSelector],
  (flightClass, economyFlights, businessFlights) => {
    switch (flightClass) {
      case FLIGHT_CLASSES.ECONOMY:
        return economyFlights;
      case FLIGHT_CLASSES.BUSINESS:
        return businessFlights;
      default:
        return null;
    }
  },
);

export const visibleFlightsCountSelector = createSelector(
  [flightSliceSelector],
  (flights) => flights.visibleFlightsCount,
);

export const cheapestFlightSelector = (state): FlightsData | undefined =>
  economyFlightsSelector(state)[0];

export const cheapestBusinessFlightSelector = (
  state,
): FlightsData | undefined => businessFlightsSelector(state)[0];

export const minFuelChargeSelector = createSelector(
  economyFlightsSelector,
  (economyFlights) => economyFlights[0]?.back[0].fuel_charge || 0,
);

export const selectCurrentFlightPrice = createSelector(
  [flightsListSelector, (_state, flight_pair_id) => flight_pair_id],
  (flights, flightPairId) =>
    flights?.find((flight) => flight.back[0].flight_pair_id === flightPairId)
      ?.total_package_price,
);

export const economyFlightsCountSelector = (state) =>
  economyFlightsSelector(state).length;

export const businessFlightsCountSelector = (state) =>
  businessFlightsSelector(state).length;

export const flightsCountSelector = (state) =>
  economyFlightsCountSelector(state) + businessFlightsCountSelector(state);

export const cheapestFlightOptionIdSelector = (state) =>
  cheapestFlightSelector(state)?.back[0].flight_pair_id || null;

export const cheapestFlightTourIdSelector = (state) =>
  cheapestFlightSelector(state)?.back[0].tour_id;

export const cheapestFlightOperatorIdSelector = (state) =>
  cheapestFlightSelector(state)?.back[0].operator_id;

/**
 * Минимальная цена для отображения на фильтрах-ползунках
 */
export const getFlightsCheapestPrice = createSelector(
  [economyFlightsSelector, businessFlightsSelector],
  (economFlights, businessFlights) => {
    if (economFlights?.length) return economFlights[0].total_package_price;
    if (businessFlights?.length) return businessFlights[0].total_package_price;
    return 0;
  },
);
