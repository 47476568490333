import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { cigaretteValuesSelector } from 'src/store/enqueue/selectors';
import {
  getIsMobile,
  getIsIframe,
  getAppName,
  searchTypeSelector,
  getIsTransparent,
  getIsWidgetShown,
  getIsHomepage,
  getIsWhitelabel,
  getInitialOpenedField,
  getIsAppBannerEnabled,
  getAvailableSearchTypes,
} from 'src/store/view/selectors';

import { getOrderInfo } from 'src/store/order/selectors';

import { fetchClientInfo } from 'src/store/client/actions';
import {
  getIsPaymentPath,
  getIsTouristsPath,
} from 'src/routes/Checkout/store/selectors/router';
import { MODULE_NAME } from 'src/constants';
import Header from './Header';

const mapStateToProps = (state) => {
  const appName = getAppName(state);
  const isTouristsStep =
    appName === MODULE_NAME.PACKAGE_CHECKOUT ? getIsTouristsPath(state) : false;
  const isPayStep =
    appName === MODULE_NAME.PACKAGE_CHECKOUT ? getIsPaymentPath(state) : false;
  const isHotelPayment =
    appName === MODULE_NAME.HOTEL_CHECKOUT ? getIsPaymentPath(state) : false;
  return {
    isMobile: getIsMobile(state),
    isIframe: getIsIframe(state),
    appName,
    orderInfo: getOrderInfo(state),
    cigaretteValues: cigaretteValuesSelector(state),
    pageSearchType: searchTypeSelector(state),
    isTransparent: getIsTransparent(state),
    isWidgetShown: getIsWidgetShown(state),
    isHomepage: getIsHomepage(state),
    isWhiteLabel: getIsWhitelabel(state),
    initialOpenedField: getInitialOpenedField(state),
    isAppBannerEnabled: getIsAppBannerEnabled(state),
    availableSearchTypes: getAvailableSearchTypes(state),
    isTouristsStep,
    isPayStep,
    isHotelPayment,
  };
};

const mapDispatchToProps = {
  fetchClientInfo,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Header);
