import React from 'react';
import QRCode from 'qrcode';
import { QR_CODE_DATA_TEST_ID } from './constants';

export interface Colors {
  dark?: string;
  light?: string;
}

export interface QRCodeOptions {
  type?: 'low' | 'medium' | 'quartile' | 'high';
  margin?: number;
  scale?: number;
  width?: number;
  color?: Colors;
  version?: number;
}

export interface IQRCode {
  text: string;
  options?: QRCodeOptions;
  className?: string;
}

export const QRCodeCanvas = React.memo(
  <T extends HTMLCanvasElement>({
    text,
    options,
    className,
  }: IQRCode): JSX.Element => {
    const inputRef = React.useRef<T>(null);

    React.useEffect(() => {
      if (inputRef && inputRef.current) {
        QRCode.toCanvas(inputRef.current, text, options, (error: Error) => {
          if (error) {
            throw error;
          }
        });
      }
    }, [inputRef, text, options]);

    return (
      <canvas
        ref={inputRef}
        className={className}
        data-testid={QR_CODE_DATA_TEST_ID}
      />
    );
  },
);
