import { createSelector } from '@reduxjs/toolkit';
import { get, isEmpty } from 'lodash';

import { i18n } from '@lt/localization';

import { getRouletteHotelTitle } from 'src/utils/stringUtils';

import { flightsListSelector } from 'checkout/CheckoutRoutes/PackageCheckout/store/flights/selectors';
import type { Coordinates } from 'src/components/YandexMap/types';
import { getRequestId, getOperatorRoomName } from './package';

import { AVERAGE_TRANSFER_SPEED } from '../constants/room';
import type { HotelStateType } from '../hotel/types';

const getHotel = (state): HotelStateType => state.hotel;

export const getHotelAnalytics = getHotel;

export const getHotelName = createSelector(getHotel, (hotel) => hotel.name);

export const getHotelStars = createSelector(getHotel, (hotel) => hotel.stars);

export const getHotelImages = createSelector(getHotel, (hotel) => hotel.images);

export const getHotelImagesGallery = createSelector(
  [getHotelImages],
  (images) => {
    const imagesArr = images ?? [];
    return imagesArr.map((image) => get(image, ['file', 'x620', 'url']));
  },
);

export const getHotelImage = createSelector(getHotelImages, (images) =>
  get(images, [0, 'file']),
);

export const getHotelCheckoutImagesGallery = createSelector(
  getHotelImages,
  (images) => {
    const imagesArr = images ?? [];
    return imagesArr.map((image) => ({
      smallImage: image.file.x620.url,
      fullImage: image.file.x900.url,
      id: image.id,
    }));
  },
);

export const getHotelCheckoutMapImage = createSelector(
  getHotelCheckoutImagesGallery,
  (images) => images[0]?.smallImage,
);

export const getHotelImageSmall = createSelector(
  getHotelImage,
  (image) => image && image.x55x56.url,
);

export const getHotelImageFull = createSelector(
  getHotelImage,
  (image) => image && image.x620.url,
);

export const getAirports = createSelector(getHotel, (hotel) => hotel.airports);

/**
 * Возвращает список доступных аэропортов с учётом перелётов
 */
export const getDestinationAirports = createSelector(
  [getAirports, flightsListSelector],
  (airports, flights) => {
    if (!flights || isEmpty(airports)) {
      return null;
    }

    const availableAirports = flights
      .map((flight) => flight.to.destination && flight.to.destination.id)
      .filter((id) => id);

    const destinationAirports = airports
      .filter((airport) => availableAirports.includes(airport.id))
      .map(({ distance, ...props }) => ({
        ...props,
        transferTime: (distance / AVERAGE_TRANSFER_SPEED) * 3600, // get time in seconds
      }));

    return destinationAirports;
  },
);

export const getHotelRating = createSelector(getHotel, (hotel) =>
  Number(hotel.rating),
);

export const getHotelCoordinates = createSelector(
  getHotel,
  (hotel): Coordinates | null => {
    const { lat, long } = hotel;
    if (!Number(lat) || !Number(long)) {
      return null;
    }
    return [long, lat];
  },
);

const getHotelCountry = createSelector(getHotel, (hotel) => hotel.country);

export const getHotelCountryIso2 = createSelector(
  getHotelCountry,
  (country) => country.iso2,
);

export const getHotelCountryName = createSelector(
  getHotelCountry,
  (country) => country.name_ru,
);

export const getHotelCountryForm2 = createSelector(
  getHotelCountry,
  (country) => country.name_ru_form2,
);

export const getCountryToText = createSelector(
  getHotelCountry,
  (country) => country.name_ru_form3,
);

export const getHotelCountryID = createSelector(
  getHotel,
  (hotel) => hotel.country_id,
);

export const getHotelPlace = createSelector(
  getHotel,
  (hotel) => hotel.place.name_ru,
);

export const getHotelLocation = createSelector(
  [getHotelCountryName, getHotelPlace],
  (country, place) => `${place}, ${country}`,
);

export const getHotelPublicUrl = createSelector(
  getHotel,
  (hotel) => hotel.public_url,
);

export const getHotelLinkWithRequestId = createSelector(
  [getHotelPublicUrl, getRequestId],
  (url, requestId) => `${url}?request_id=${requestId}&show_prices=true`,
);

export const getHotelInfo = createSelector(
  getHotel,
  (hotel) => hotel.hotel_info,
);

export const getHotelRoulette = createSelector(getHotel, (hotel) =>
  Boolean(hotel.roulette),
);

export const getHotelTitle = createSelector(
  [getHotelName, getHotelStars, getHotelRoulette],
  (name, stars, isRoutlette) =>
    isRoutlette ? getRouletteHotelTitle(name, stars) : name,
);

export const getOperatorRoomNameWithRoulette = createSelector(
  [getOperatorRoomName, getHotelRoulette],
  (roomName, isRoulette) =>
    isRoulette ? i18n.t('checkout.roulette') : roomName,
);

export const getHotelMetapolicies = createSelector(
  getHotel,
  (hotel) => hotel.metapolicies || [],
);

export const getFacts = createSelector(getHotel, (hotel) => hotel.facts);

export const getAddress = createSelector(getHotel, (hotel) => hotel.address);

export const getCheckinFromInfo = createSelector(
  [getFacts],
  (facts): string => facts.checkin_from,
);

export const getCheckoutUntilInfo = createSelector(
  [getFacts],
  (facts): string => facts.checkout_until,
);
