import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { isSSR } from 'src/helpers/ssr';
import type { IHeaderBanner } from './types';

const headerBanner = isSSR ? undefined : window.headerBanner;

const isAvailable = !!headerBanner;
const preparedHeaderBanner = headerBanner
  ? {
      desktopText: headerBanner.desktop_text,
      imageSrc: headerBanner.image_links,
      redirectUrl: headerBanner.redirect_url,
      backgroundColor: headerBanner.background_color,
      textColor: headerBanner.text_color,
    }
  : null;

const initialState: IHeaderBanner = {
  bannerData: preparedHeaderBanner,
  isHidden: false,
  isAvailable,
};

const headerBannerSlice = createSlice({
  name: 'headerBanner',
  initialState,
  reducers: {
    setIsHiddenHeaderBanner: (
      state,
      action: PayloadAction<IHeaderBanner['isHidden']>,
    ) => {
      state.isHidden = action.payload;
    },
  },
});

export const { setIsHiddenHeaderBanner } = headerBannerSlice.actions;

const { name, reducer } = headerBannerSlice;

export { reducer as default, name };
