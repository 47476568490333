import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  data: null,
};

const cashbackSlice = createSlice({
  name: 'cashback',
  initialState,
  reducers: {
    setCashbackData(state, action) {
      state.data = action.payload;
    },
  },
});

export const { setCashbackData } = cashbackSlice.actions;

export default cashbackSlice.reducer;
