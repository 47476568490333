import { ButtonTs } from '@lt/components';
import Textarea from '@lt/components/components/Form/components/Textarea';
import { AttentionTriangle } from '@lt/components/icons-react';
import { useTranslation } from '@lt/localization';
import { Form, Formik } from 'formik';
import React from 'react';
import styled, { css } from 'styled-components';
import { StyledNumber } from './ChooseRating';
import type { NpsProps } from '../NpsForm';
import { NPS_EXPANDED_FORM_TEST_ID } from '../constants';

interface Props extends Pick<NpsProps, 'errorOccured' | 'isMobile'> {
  submitForm: (comment: string) => void;
  rating: number;
  setComment: (value: string) => void;
}
const initialValues = {
  comment: '',
};

const ExpandedForm = (props: Props) => {
  const { submitForm, isMobile, rating, errorOccured, setComment } = props;
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => submitForm(values.comment)}
    >
      {() => (
        <Form>
          <StyledContainer
            $isMobile={isMobile}
            data-testid={NPS_EXPANDED_FORM_TEST_ID}
          >
            <StyledThanksMessage>{t('nps.thanksMessage')}</StyledThanksMessage>
            <StyledYourRating>{t('nps.yourRating')}</StyledYourRating>
            <StyledNumber>{rating}</StyledNumber>
            <StyledCommentTitle>{t('nps.commentTitle')}</StyledCommentTitle>
            <StyledTextarea
              name="comment"
              placeholder={t('nps.placeholderText')}
              onChange={setComment}
            />
            <StyledButton variant="primary" type="submit" $isMobile={isMobile}>
              {t('nps.buttonText')}
            </StyledButton>
            {errorOccured && (
              <StyledErrorContainer>
                <AttentionTriangle width={24} />
                <StyledErrorMessage>
                  {t('nps.error.submitFail')}
                </StyledErrorMessage>
              </StyledErrorContainer>
            )}
          </StyledContainer>
        </Form>
      )}
    </Formik>
  );
};

const StyledContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ $isMobile }) =>
    !$isMobile &&
    css`
      padding: 24px;
    `}
`;

const StyledThanksMessage = styled.div`
  color: #17181a;
  font-size: 20px;
  font-weight: 500;
  margin-top: 16px;
`;
const StyledYourRating = styled.div`
  color: #4d4d4d;
  font-size: 15px;
  margin: 16px 0 8px;
`;
const StyledCommentTitle = styled.div`
  color: #17181a;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin-top: 24px;
  text-align: center;
`;
const StyledTextarea = styled(Textarea)`
  margin: 16px 0;
  width: 100%;
  > div > textarea {
    resize: none;
    height: 84px;
  }
`;

const StyledButton = styled(ButtonTs)<{ $isMobile: boolean }>`
  ${({ $isMobile }) =>
    $isMobile
      ? css`
          width: 100%;
          height: 48px;
          font-size: 18px;
        `
      : css`
          width: 161px;
        `}
`;

const StyledErrorContainer = styled.div`
  display: flex;
  padding: 4px 12px 4px 8px;
  align-items: center;
  border-radius: 4px;
  background: #f9eaec;
  margin-top: 16px;
  width: 100%;
`;

const StyledErrorMessage = styled.span`
  color: #17181a;
  font-size: 12px;
  line-height: 16px;
  margin-left: 8px;
`;

export { ExpandedForm };
