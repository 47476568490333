import React, { useState } from 'react';
import classnames from 'classnames';

import SearchTypeTabs from 'src/components/SearchTypeTabs';
import LazyCigarette from 'src/components/Cigarette/components/LazyCigarette';

import type { SearchType } from 'src/types/search';

import Logo from '../HeaderLogo';
import Menu from '../HeaderMenu';

import type { Props } from './types';
import styles from './headerSearchForm.module.css';

const HeaderSearchForm = ({
  cigaretteValues,
  pageSearchType,
  isMobile,
  availableSearchTypes,
  setIsWizardOpen,
  isWizardOpen = false,
  isWhiteLabel,
  inPreview = false,
  submitCigarette,
  setBodyLocked,
  initialOpenedField,
}: Props) => {
  const [searchType, setSearchType] = useState<SearchType>(
    pageSearchType ||
      (availableSearchTypes &&
        availableSearchTypes.length >= 1 &&
        availableSearchTypes[0]) ||
      'package',
  );

  const SearchTabs = (
    <SearchTypeTabs
      className={classnames(styles.searchTypeTabs, {
        [styles.mobileSearchTypeTabs]: isMobile,
      })}
      selectedTab={searchType}
      setSelectedTab={setSearchType}
      variant={{
        mobile: isMobile || false,
        wl: isWhiteLabel,
        page: 'SEARCH',
        collapsed: false,
      }}
    />
  );

  return (
    <>
      <div
        className={classnames(styles.menuWrapper, {
          [styles.mobileMenuWrapper]: isMobile,
        })}
      >
        <Logo inWidget />
        {!isMobile ? SearchTabs : null}
        <Menu className={styles.menu} isCollapsed={false} />
      </div>

      <div>
        {isMobile ? SearchTabs : null}
        <LazyCigarette
          isMobile={isMobile}
          searchType={searchType}
          cigaretteValues={cigaretteValues}
          trackSubmit={submitCigarette}
          allowPreview={!inPreview}
          inHeader
          isWhiteLabel={isWhiteLabel}
          initialOpenedField={initialOpenedField}
          isWizardOpen={isWizardOpen}
          setIsWizardOpen={setIsWizardOpen}
          setBodyLocked={setBodyLocked}
        />
      </div>
    </>
  );
};

export default HeaderSearchForm;
