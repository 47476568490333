import type {
  PutEffect,
  SagaReturnType,
  SelectEffect,
} from 'redux-saga/effects';
import { select } from 'redux-saga/effects';
import { api } from 'src/api';
import { APPLICATION_VERSION } from 'src/error/config/sentry/runTimeConfig';
import { getClientStatsData } from 'src/store/client/selectors';
import { selectOrderID, getPaymentMethod } from 'src/store/payment/selectors';
import { getIsMobile } from 'src/store/view/selectors';

export type PaymentEvent =
  | 'payment_started'
  | 'payment_failed'
  | 'payment_success';

export function* sendPayAnalytics<T extends PaymentEvent>(
  event: T,
  ...args: T extends 'payment_failed' ? [string] : []
): Generator<
  | SelectEffect
  | PutEffect
  | Promise<SagaReturnType<typeof api.postStatsPayment>>,
  void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
> {
  try {
    const [message] = args;
    const client: SagaReturnType<typeof getClientStatsData> = yield select(
      getClientStatsData,
    );
    const paymentMethod: SagaReturnType<typeof getPaymentMethod> = yield select(
      getPaymentMethod,
    );
    const partnerId = window.PARTNER ? window.PARTNER.id : null;
    const isMobile: SagaReturnType<typeof getIsMobile> = yield select(
      getIsMobile,
    );
    const orderId: SagaReturnType<typeof selectOrderID> = yield select(
      selectOrderID,
    );

    if (!paymentMethod) throw new Error('No payment method provided!');
    if (!APPLICATION_VERSION)
      throw new Error('No application version provided!');

    yield api.postStatsPayment({
      event,
      client,
      payment_method: paymentMethod,
      release_version: APPLICATION_VERSION,
      partner_id: partnerId,
      is_mobile: isMobile,
      order_id: Number(orderId),
      error: message,
      url: window.location.href,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('stats/pay error: ', e);
  }
}
