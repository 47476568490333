import React from 'react';

import { HotelStars } from '@lt/components';
import { Star } from '@lt/components/icons-react/userflow';
import styles from './BannerStars.module.css';
import {
  BANNER_STARS_TEST_ID,
  FULL_STARS_TEST_ID,
  ONE_STAR_TEST_ID,
} from '../constants';

type Props = {
  reviewsCountText: string;
  rating: number;
  className?: string;
};

export const BannerStars = ({
  reviewsCountText,
  rating,
  className,
}: Props): JSX.Element => {
  const fullStarsCount = Math.floor(rating);
  const decimalPart = rating - fullStarsCount;
  return (
    <div
      className={className || styles.data}
      data-testid={BANNER_STARS_TEST_ID}
    >
      {rating && (
        <>
          <HotelStars
            className={styles.stars}
            value={fullStarsCount}
            size={12}
            data-testid={FULL_STARS_TEST_ID}
          />
          {decimalPart !== 0 && (
            <Star
              className={styles.star}
              height={12}
              viewBox={`0 0 ${25 * decimalPart} 24`}
              data-testid={ONE_STAR_TEST_ID}
            />
          )}
        </>
      )}
      {reviewsCountText && <p className={styles.reviews}>{reviewsCountText}</p>}
    </div>
  );
};
