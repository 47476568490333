import { takeLatest, put } from 'redux-saga/effects';
import type { SagaReturnType } from 'redux-saga/effects';

import { api } from 'src/api';
import { INIT_APP } from '../view/constants';
import { setCashbackData } from './slice';

function* fetchCashbackData() {
  try {
    const data: SagaReturnType<typeof api.getPartnerSettings> =
      yield api.getPartnerSettings();
    if (data) {
      yield put(setCashbackData(data));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Fetch cashback data error', e);
  }
}

export default function* cashbackSaga() {
  yield takeLatest(INIT_APP, fetchCashbackData);
}
