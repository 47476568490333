import React from 'react';

import { i18n } from '@lt/localization';
import classnames from 'classnames';
import { BannerLogo } from '@lt/components/icons-react';
import styles from './appInformation.module.css';
import { BannerStars } from '../BannerStars/BannerStars';

type Props = {
  reviewsCountText: string;
  className?: string;
  rating: number;
};

export const AppInformation = ({
  className,
  reviewsCountText,
  rating,
}: Props): JSX.Element => (
  <div className={classnames(styles.container, className)}>
    <div className={styles.logo}>
      <BannerLogo />
    </div>
    <div className={styles.content}>
      <p className={styles.title}>
        {i18n.t('mobile_apps_banner.app_information.title')}
      </p>
      <p className={styles.description}>
        {i18n.t('mobile_apps_banner.app_information.description')}
      </p>
      <BannerStars reviewsCountText={reviewsCountText} rating={rating} />
    </div>
  </div>
);
