import React from 'react';
import cn from 'classnames';

import { isSSR } from 'src/helpers/ssr';
import DownloadApp from './DownloadApp';
import NavigationLinks from './NavigationLinks';
import SocialLinks from './SocialLinks';
import LegalInfo from './LegalInfo';

import type { PropsFromRedux as Props } from '.';
import styles from './footer.module.css';

const Footer = ({ isMobile }: Props) => (
  <footer
    className={cn(styles.container, { [styles.mobileContainer]: isMobile })}
  >
    {!isSSR && <DownloadApp />}
    <div className={styles.linksContainer}>
      <NavigationLinks />
      <SocialLinks />
    </div>
    <LegalInfo />
  </footer>
);

export default Footer;
