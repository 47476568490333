export const nestedFilters = ['arrival_airports', 'departure_airports'];

export const sliderFilters = [
  'forward_departure_time',
  'backward_departure_time',
  'max_flight_duration',
];

export const optionsIdArray = [
  'number',
  'interval',
  'flight_type',
  'flight_class',
  'luggage_type',
  'transition_time',
  'date',
];

export const MIN_NUM_OF_FLIGHTS_TO_SHOW_FILTERS = 3;

export const APPLY_SINGLE_CHOISE_FILTERS = 'APPLY_SINGLE_CHOISE_FILTERS';
