import React from 'react';
import cn from 'classnames';

import { i18n } from '@lt/localization';
import { Link } from '@lt/components';
import {
  Vk as VkIcon,
  Telegram as TelegramIcon,
  Youtube as YoutubeIcon,
} from '@lt/components/icons-react/rebrand';

import type { Props } from './types';
import styles from './socialLinks.module.css';

const SocialLinks = ({ className = '', isMobile }: Props) => {
  const iconClassName = cn(styles.icon, { [styles.mobileIcon]: isMobile });
  return (
    <div
      className={cn(
        styles.container,
        { [styles.mobileContainer]: isMobile },
        className,
      )}
    >
      <h3 className={cn(styles.title, { [styles.mobileTitle]: isMobile })}>
        {i18n.t('footer.beWithUs')}
      </h3>
      <div className={styles.linksContainer}>
        <Link href="https://vk.com/level.travel">
          <VkIcon className={iconClassName} />
        </Link>
        <Link href="https://youtube.com/channel/UC2zY7LXiFHeiMfEnNU0wY1w">
          <YoutubeIcon className={iconClassName} />
        </Link>
        <Link href="https://t.me/s/leveltravel">
          <TelegramIcon className={iconClassName} />
        </Link>
      </div>
    </div>
  );
};

export default SocialLinks;
