import { i18n } from '@lt/localization';
import React from 'react';

import type { MenuItems } from '@lt/components/components/HeaderMenu/HeaderMenuCategory';

import onClientLogOut from 'src/components/AuthNew/utils/onClientLogOut';
import {
  Search as SearchIcon,
  Heart as HeartIcon,
  Company as CompanyIcon,
  Job as JobIcon,
  Messenger as MessengerIcon,
  Help as HelpIcon,
  Gift as GiftIcon,
  Orders as OrdersIcon,
  Logout as LogoutIcon,
  Seagull as SeagullIcon,
} from '@lt/components/icons-react/rebrand';
import {
  ABOUT_US_TEST_ID,
  CALL_CENTER_TEST_ID,
  CAREER_TEST_ID,
  GIFTS_TEST_ID,
  LOG_IN_TEST_ID,
  LOG_OUT_TEST_ID,
  NEW_SEARCH_TEST_ID,
  PROFILE_TEST_ID,
  WISHLIST_TEST_ID,
  WRITE_US_TEST_ID,
} from '../components/SideNavMenu/constants';

interface MobileMenuItem {
  active: boolean;
  icon: JSX.Element;
  text?: string;
  href?: string;
  dataTestId: string;
  external?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

type MobileMenuItems = MobileMenuItem[];

interface MobileMenuParams {
  isWhitelabel: boolean;
  isLoggedIn: boolean;
  onToggle: () => void;
  onShowAuthPopup: () => void;
  onShowFeedbackPopup: () => void;
  onLogOut?: () => void;
  customContacts?: MenuItems;
}

export const getMobileMenu = ({
  isWhitelabel,
  isLoggedIn,
  onToggle,
  onShowAuthPopup,
  onShowFeedbackPopup,
  onLogOut,
  customContacts,
}: MobileMenuParams): MobileMenuItems => [
  {
    active: true,
    icon: <SearchIcon height={24} />,
    text: i18n.t('headerMenu.mobile.search'),
    href: '/',
    dataTestId: NEW_SEARCH_TEST_ID,
  },
  {
    active: true,
    text: i18n.t('headerMenu.mobile.wishlist'),
    href: '/wishlist',
    dataTestId: WISHLIST_TEST_ID,
    icon: <HeartIcon height={24} />,
  },
  {
    active: !isWhitelabel,
    icon: <CompanyIcon height={24} />,
    text: i18n.t('headerMenu.mobile.about_us'),
    href: '/about',
    dataTestId: ABOUT_US_TEST_ID,
  },
  {
    active: !isWhitelabel,
    icon: <JobIcon height={24} />,
    text: i18n.t('headerMenu.mobile.career'),
    href: '//career.level.travel/',
    external: true,
    dataTestId: CAREER_TEST_ID,
  },
  {
    active: !isWhitelabel,
    icon: <MessengerIcon height={24} />,
    text: i18n.t('headerMenu.mobile.write_us'),
    href: '/',
    onClick: (e) => {
      e.preventDefault();
      onToggle();
      onShowFeedbackPopup();
    },
    dataTestId: WRITE_US_TEST_ID,
  },
  {
    active: true,
    icon: <HelpIcon height={24} />,
    text: isWhitelabel
      ? customContacts?.title
      : i18n.t('headerMenu.mobile.contacts'),
    href: isWhitelabel ? customContacts?.link : '//help.level.travel/',
    dataTestId: CALL_CENTER_TEST_ID,
  },
  {
    active: !isWhitelabel,
    icon: <GiftIcon height={24} />,
    text: i18n.t('headerMenu.mobile.certificate'),
    href: '/gift',
    dataTestId: GIFTS_TEST_ID,
  },

  {
    active: !isLoggedIn,
    icon: <SeagullIcon height={24} />,
    text: i18n.t('headerMenu.login'),
    href: '/',
    onClick: (e) => {
      e.preventDefault();
      onToggle();
      onShowAuthPopup();
    },
    dataTestId: LOG_IN_TEST_ID,
  },
  {
    active: isLoggedIn,
    icon: <OrdersIcon height={24} />,
    text: i18n.t('headerMenu.profile'),
    href: '/client',
    dataTestId: PROFILE_TEST_ID,
  },
  {
    active: isLoggedIn,
    icon: <LogoutIcon height={24} />,
    text: i18n.t('headerMenu.logout'),
    href: '/',
    onClick: async (e) => {
      e.preventDefault();
      onToggle();

      if (!onLogOut) return;
      onClientLogOut(onLogOut);
    },
    dataTestId: LOG_OUT_TEST_ID,
  },
];
